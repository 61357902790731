<template>
	<div class="uc-page">
		<van-nav-bar :title="$t('hangqing')"/>

		<div class="wrapper">
			<div class="quo-card">
				<div class="quo-head">
					<img src="@/static/img/trlogo2.0ec18c76.png">
					<div>{{$t('muqiangongyountaijiqirenxiadan', {n: userInfo.machineOrderQuantity})}}</div>
				</div>
				<div class="quo-body" v-if="orderlist.length > 0" style="color: rgb(255, 255, 255);">
					<div class="quo-item">
						<span class="iconfont icon-jinbi"></span>
						<span>{{$t('dangqianjiqirendengji')}}：</span>
						<span class="v">{{orderlist[0].title}}</span>
					</div>
					<div class="quo-item">
						<span class="iconfont icon-time"></span>
						<span>{{$t('youxiaoqi')}}：</span>
						<span class="v">{{orderlist[0].timelimit}}{{$t('tian')}}</span>
					</div>
					<div class="quo-item">
						<span class="iconfont icon-qianbao"></span>
						<span>{{$t('zhuangtai')}}：</span>
						<span class="v">{{$t('zhengchang')}}</span>
					</div>
					<div class="quo-item">
						<span class="iconfont icon-qianbao"></span>
						<span>{{$t('qidongzijin')}}：</span>
						<span class="v">{{orderlist[0].price}}{{$currencyUnit}}</span>
					</div>
				</div>
				<div class="quo-body" v-else style="color: rgb(255, 255, 255);">
					{{$t('zanwushuju')}}
				</div>

			</div>
			<div class="rec-panel quo-panel" style="min-height: 200px;">
				<div class="quo-bar flex-center">
					<div class="flex_bd flex-ex-bar">
						<div class="expace-list">
							<img v-for="(item, index) in wlimgs"
								:style="item.isWid ? 'height:.43478rem;' : 'width: 3.6rem;height: auto;' "
								:src="item.img">
						</div>
					</div>
					<i @click="ExchangeShow = true" class="van-icon van-icon-add">
					</i>
				</div>
				<div class="rec-cell flex-center" v-for="(item,index) in exchangeRate">
					<img :src="item.logo"
						class="rec-icon">
					<div class="flex_bd" style="width: 100px;">
						<div class="rec-tit">{{item.currency}}</div>
					</div>
					<div class="flex_bd">
						<div class="text" style="font-size: 14px;">{{item.lastPrice}}</div>
					</div>
					<div class="rec-right">
						<div class="rec-per" :class="item.priceChangePercent > 0 ? 'text-green' : 'text-red' " style="width: 68px;">
							<span>{{item.priceChangePercent}}%</span>
						</div>
					</div>
				</div>
			
			</div>
			<!-- <div class="rec-panel">
				<div role="feed" class="van-list">
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/pc227TP7pFASuA3979TuC2A0eaP27PL2.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">日产</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:12:32</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.000843</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/DfcK2NZLS99oDFYdsgldeDZYd2ksDsFP.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">LTC</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:12:04</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2.418290</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/e5KEPhKe337PP8kHzttp3UOl9E3959k5.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">索尔</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:11:10</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2.294940</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:10:33</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.040550</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:09:27</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-dn"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-red">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">-0.646920</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/nYXEQ1EL64ixiijJDIwqzjL5HgqIQJWI.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">以太坊</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:07:16</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">1.427700</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:06:24</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.582700</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/x767Vp2SP778p77xL80sGHGPO7lVz235.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">MDX</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:01:00</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.003321</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/x767Vp2SP778p77xL80sGHGPO7lVz235.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">MDX</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 18:00:38</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-dn"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-red">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">-0.000606</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/e5KEPhKe337PP8kHzttp3UOl9E3959k5.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">索尔</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:59:32</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-dn"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-red">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">-0.362620</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/e5KEPhKe337PP8kHzttp3UOl9E3959k5.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">索尔</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:58:01</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2.130580</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/nYXEQ1EL64ixiijJDIwqzjL5HgqIQJWI.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">以太坊</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:57:45</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">1.972410</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:56:36</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">1.260490</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:56:30</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.744920</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/DfcK2NZLS99oDFYdsgldeDZYd2ksDsFP.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">LTC</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:53:39</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.734430</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/e5KEPhKe337PP8kHzttp3UOl9E3959k5.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">索尔</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:53:24</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.515280</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/pc227TP7pFASuA3979TuC2A0eaP27PL2.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">日产</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:53:12</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.007520</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/e5KEPhKe337PP8kHzttp3UOl9E3959k5.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">索尔</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:50:01</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2.081020</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/FZ336637xhHNZFzEOzAh33X79CCHcnFe.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">比特币</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:49:07</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.032490</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="rec-cell flex-center">
						<img src="https://kones3.s3.ap-southeast-1.amazonaws.com/upload/20220507/x767Vp2SP778p77xL80sGHGPO7lVz235.png"
							class="rec-icon">
						<div class="flex_bd">
							<div class="rec-tit">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">MDX</font>
								</font>
							</div>
							<div class="rec-time">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">2022-11-05 17:48:54</font>
								</font>
							</div>
						</div>
						<div class="rec-icon">
							<div style="font-size: 20px;">
								<i class="iconfont icon-up"></i>
							</div>
						</div>
						<div class="rec-right" style="min-width: 65px;">
							<div class="rec-per text-green">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">0.005816</font>
								</font>
							</div>
							<div class="text theme_2">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">收入</font>
								</font>
							</div>
						</div>
					</div>
					<div class="van-list__placeholder"></div>
				</div>
			</div>
		
		 -->
		</div>
	
		<van-popup class="page-popup notice-popup" v-model="ExchangeShow">
			<div class="quot-dialog">
				<div class="quot-title">{{$t('qujiaoyisuo')}}</div>
				<div class="exp-rows van-row">
					<div class="van-col van-col--12" @click="onJisUrl(item)" v-for="(item, index) in wlimgs" style="padding-right: 10px;">
						<div role="button" tabindex="0" class="van-cell van-cell--clickable van-cell--center">
							<div class="van-cell__title">
								<img :style="item.isWid ? 'height:.43478rem;' : 'width: 3.6rem;height: auto;' " :src="item.img" alt="" class="ex-icon">
							</div>
							<i class="van-icon van-icon-arrow van-cell__right-icon">
							</i>
						</div>
					</div>
				</div>
			</div>
			<i @click="ExchangeShow = false" role="button" tabindex="0"
				class="van-icon van-icon-cross van-popup__close-icon van-popup__close-icon--top-right">
			</i>
		</van-popup>
	</div>
</template>

<script>
	import {
		apiUserInfo,
		apiExchangeRate,
		apiFundCategory,
		apiFundOrderList
	} from '@/request/api'; //api接口
	
	let timeA = null;
	export default {
		data() {
			return {
				exchangeRate: {
					'BTC' : {
						currency:"BTC",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/btc.svg'),
						priceChangePercent:"0",
					},
					'ETH' : {
						currency:"ETH",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/eth.svg'),
						priceChangePercent:"0",
					},
					'TRX' : {
						currency:"TRX",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/trx.svg'),
						priceChangePercent:"0",
					},
					'ATOM' : {
						currency:"ATOM",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/atom.svg'),
						priceChangePercent:"0",
					},
					'CRV' : {
						currency:"CRV",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/crv.svg'),
						priceChangePercent:"0",
					},
					'DOGE' : {
						currency:"DOGE",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/doge.svg'),
						priceChangePercent:"0",
					},
					'EOS' : {
						currency:"EOS",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/eos.svg'),
						priceChangePercent:"0",
					},
					
					'FIL' : {
						currency:"FIL",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/fil.svg'),
						priceChangePercent:"0",
					},
					'JST' : {
						currency:"JST",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/jst.svg'),
						priceChangePercent:"0",
					},
					'LINK' : {
						currency:"LINK",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/link.svg'),
						priceChangePercent:"0",
					},
					'LTC' : {
						currency:"LTC",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/ltc.svg'),
						priceChangePercent:"0",
					},
					'MDX' : {
						currency:"MDX",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/mdx.svg'),
						priceChangePercent:"0",
					},
					'ONT' : {
						currency:"ONT",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/ont.svg'),
						priceChangePercent:"0",
					},
					'SOL' : {
						currency:"SOL",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/sol.svg'),
						priceChangePercent:"0",
					},
					'SUN' : {
						currency:"SUN",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/sun.svg'),
						priceChangePercent:"0",
					},
					
					'WAVES' : {
						currency:"WAVES",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/waves.svg'),
						priceChangePercent:"0",
					},
					'XRP' : {
						currency:"XRP",
						lastPrice:0,
						logo:require('../../static/img/currency_icon/xrp.svg'),
						priceChangePercent:"0",
					},
				},
				ExchangeShow: false,
				wlimgs: [
					{
						img: require('../../static/img/hb/binan.svg'),
						url: 'https://www.binance.com/en',
						isWid: false
					},
					{
						img: require('../../static/img/hb/bitfinex.svg'),
						url: 'https://www.bitfinex.com/',
						isWid: false
					},
					{
						img: require('../../static/img/hb/bybit.svg'),
						url: 'http://www.bybit.com/',
						isWid: true
					},
					{
						img: require('../../static/img/hb/ftx.svg'),
						url: 'https://ftx.us/',
						isWid: false
					},
					{
						img: require('../../static/img/hb/gate.png'),
						url: 'https://www.gate.io/zh',
						isWid: true
					},
					{
						img: require('../../static/img/hb/gemini.png'),
						url: 'https://gemini.com/',
						isWid: true
					},
					{
						img: require('../../static/img/hb/huobi.svg'),
						url: 'https://www.huobi.com/en-us/',
						isWid: false
					},
					{
						img: require('../../static/img/hb/okx.svg'),
						url: 'https://www.okex.com/',
						isWid: false
					},
					{
						img: require('../../static/img/hb/poloniex.svg'),
						url: 'https://poloniex.com/',
						isWid: false
					},
					{
						img: require('../../static/img/hb/zb.svg'),
						url: 'https://www.zb.com/en/',
						isWid: false
					},
				],
				ExchangeList: [
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/U51M2g74MgajE7gG11ZMsa2A744cyA7G.jpg',
						url: 'https://www.huobi.com/en-us/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/u591YO96Ks8ssXT9798bYt556b45Oon1.jpg',
						url: 'https://www.okex.com/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/WUCCiiPqq11u0t1pTJtxupxGoXOFjqKO.jpg',
						url: 'https://www.binance.com/en'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/wiyrLQeEiqzeawwg0P4qAeQiLqZt0iI9.jpg',
						url: 'https://www.bitfinex.com/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/n6AIZ0PhWEHO60HA6Iw64E4hW055Y0i7.jpg',
						url: 'https://poloniex.com/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/dc4kYzdCpCyXsuZFUXjCPyYCkCuPucIE.jpg',
						url: 'https://gemini.com/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/MvqfVVOWQR8OKwi8pdo8PRf8Q0W8Z88I.jpg',
						url: 'https://ftx.us/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220116/LBFFVK33nxIEdfxIviGk9E733i2fdve3.png',
						url: 'https://www.zb.com/en/'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/whVZr4K7IrkKh7jHrR4YOr4RR3eY4j3h.jpg',
						url: 'https://www.gate.io/zh'
					},
					{
						img: 'https://nigeria09.s3.af-south-1.amazonaws.com/upload/20220115/Csi1xz1I2Pimgx2g66zKiS22s6iqxilL.jpg',
						url: 'http://www.bybit.com/'
					}
				],
				userInfo: {},
				orderlist: []
			}
		},
		created() {
			// this.getExchangeRate();
			this.getUserInfo()
			this.getFundOrderList()
		},
		mounted() {
			let exSte = localStorage.getItem('exchangeRate');
			if (exSte) {
				exSte = JSON.parse(exSte);
				
				for(let ekey in exSte) {
					let currency = exSte[ekey].currency.toLowerCase();
					exSte[ekey]['logo'] = require('../../static/img/currency_icon/'+currency+'.svg');
				} 
				
				this.exchangeRate = exSte;
				
			}
			setTimeout(()=>{
				this.handdleMsg()
			},1000)	
		},
		destroyed(){
			clearInterval(timeA);
		},
		methods: {
			getFundOrderList() {
				apiFundOrderList().then(res => {
					if (res.code!=0) {
						return this.$toast(res.msg);
					}
					
					let listo = [];
					
					res.data.forEach((item,index) => {
						if (item.status == 1) {
							let times = item.timedifference;
							let t = parseInt(times /60/60/24);
							let h = parseInt(times / 60 / 60 % 24);
							let m = parseInt(times / 60 % 60);
							let s = parseInt(times % 60);
							
							item.jgTime = t + this.$t("tian") + h + this.$t("shi") + m +this.$t("fen");
							
							listo.push(item);
						}
						
					})
					this.orderlist = listo;
				})
			},
			getUserInfo() {
				apiUserInfo().then(res => {
					this.userInfo =res.data;
				})
			},
			getExchangeRate() {
				apiExchangeRate().then(res => {
					this.exchangeRate = res.data;
					
				})
			},
			onJisUrl(item) {
				location.href = item.url
			},
			handdleMsg() {
				let that = this;
				if (that.$global.ws && that.$global.ws.readyState == 1) {
					that.$global.ws.send(
						JSON.stringify({
							id: 1,
							method: "SUBSCRIBE",
							params: [
								// "!miniTicker@arr@3000ms",
								"!ticker_1h@arr@3000ms",
								// "!ticker_4h@arr@3000ms"
							]
						})
					);
				}
				that.$global.ws.onmessage = (res) => {
					const msg = JSON.parse(res.data);
					let list = [];
					if (msg) {
						msg.data.forEach((item, index) => {
							if (item.s.indexOf('USDT') != -1) {
								let ss = item.s.replace(/USDT/g, "");
								let ssarr = ['BTC', 'ETH', 'TRX', 'DOGE', 'FIL', 'XRP', 'ONT', 'LINK', 'MDX',
									'SOL', 'JST', 'SUN', 'CRV', 'EOS', 'WAVES', 'ATOM', 'LTC'
								];
								if (ssarr.indexOf(ss) != -1) {
									
									let lastPrice = item.c;
									
									// if (ss == 'BTC'||ss == 'ETH' || ss == "LTC") {
									lastPrice = parseInt(item.c * 10000) / 10000;
									// }
									
									this.$set(this.exchangeRate, ss, {
										currency: ss,
										logo: this.exchangeRate[ss].logo,
										lastPrice: lastPrice,
										priceChangePercent: item.P
									})
								}
									
							}
						})
						localStorage.setItem('exchangeRate', JSON.stringify(this.exchangeRate));
					}
					
				};
			},
		}
	}
</script>

<style scoped>
	.uc-page .van-nav-bar {
		background:#4b4d52
	}
	
	.van-row {
		background-color: inherit;
		box-shadow: none;
	}
	
	.page-popup .van-popup__close-icon {
		background-color:#000;
		color:#fff
	}
	.van-popup__close-icon--top-right {
	    top: 0.38647rem;
	    right: 0.38647rem;
	}
	.uc-page {
		background-image:url(@/static/img/hq-t-bg.9bc16461.png);
		background-repeat:no-repeat;
		background-size:100% 3.69565rem
	}
	.icon-jinbi,.icon-qianbao,.icon-time {
		color:#fff
	}
	.ts {
		color:#a0a0a0
	}
	.text-green {
		color:#01b176
	}
	.text-red {
		color:#ff4545
	}
	.quo-bar {
		padding:0 .28986rem;
		margin:0 -.48309rem;
		height:1.44928rem;
		box-shadow:0 .04831rem .48309rem rgba(0,0,0,.1);
		border-radius:.24155rem
	}
	.quo-bar .van-icon{
	    font-size: 20px;
	}
	.quo-bar img {
		height:.43478rem;
		margin-right:.14493rem
	}
	.quo-bar .van-icon {
		color:#079663
	}
	.expace-list {
		display:flex;
		align-items:center
	}
	.expace-list img {
		height:.5314rem;
		margin-right:.36232rem
	}
	.flex-ex-bar {
		overflow-y:hidden;
		display:flex;
		align-items:center;
		border-radius:.24155rem;
		padding:0 .36232rem
	}
	.flex-ex-bar::-webkit-scrollbar {
		display:none
	}

</style>
