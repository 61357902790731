<template>
	<div class="page page-wt">
		<van-nav-bar :title="$t('chongzhi')" 
		:fixed="true" 
		:placeholder="true" 
		left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
		
		<div class="wrapper">
			<div class="wt-panel">
				
				<div class="wt-input_items">
					<div class="hd">{{$t('yinhangmingcheng')}}
					</div>
					<div class="input-text van-cell van-field" @click="onCoypBtnClick(bankInfo.bank_name)">
						<div class="van-cell__value van-cell__value--alone van-field__value">
							<div class="van-field__body">
								<input 
								type="tel" 
								v-model="bankInfo.bank_name" 
								disabled 
								inputmode="numeric" 
								:placeholder="$t('qingshuruyinhangmingchengexuanze')" 
								class="van-field__control">
							</div>
						</div>
						<span class="cory">{{$t('fuzhi')}}</span>
					</div>
				</div>
				
				<div class="wt-input_items">
					<div class="hd">{{$t('zhanghumingcheng')}}
					</div>
					<div class="input-text van-cell van-field"  @click="onCoypBtnClick(bankInfo.account_name)">
						<div class="van-cell__value van-cell__value--alone van-field__value">
							<div class="van-field__body">
								<input type="tel" 
								v-model="bankInfo.account_name" 
								disabled inputmode="numeric" 
								:placeholder="$t('qingshuruyinhangmingcheng')" 
								class="van-field__control">
							</div>
						</div>
						<span class="cory">{{$t('fuzhi')}}</span>
					</div>
				</div>
				<div class="wt-input_items">
					<div class="hd">{{$t('kahao')}}
					</div>
					<div class="input-text van-cell van-field"  @click="onCoypBtnClick(bankInfo.card_number)">
						<div class="van-cell__value van-cell__value--alone van-field__value">
							<div class="van-field__body">
								<input type="tel" 
								v-model="bankInfo.card_number" 
								disabled 
								inputmode="numeric" 
								:placeholder="$t('qingshuruyinhangkahao')" 
								class="van-field__control">
							</div>
						</div>
						<span class="cory">{{$t('fuzhi')}}</span>
					</div>
				</div>
				
				
				
				<div class="wt-input_items">
					<div class="hd">{{$t('jine')}}（
						<span style="color: rgb(221, 74, 104); font-size: 12px;">
							{{$currencyUnit}}
						</span>）
					</div>
					<div class="input-text van-cell van-field" @click="onCoypBtnClick(priceValue)">
						<div class="van-cell__value van-cell__value--alone van-field__value">
							<div class="van-field__body">
								<input type="tel" v-model="priceValue" disabled inputmode="numeric" :placeholder="$t('qingshurujine')" class="van-field__control">
							</div>
						</div>
						<span class="cory">{{$t('fuzhi')}}</span>
					</div>
				</div>
				
				<div class="wt-input_items">
					<div class="hd">{{$t('shangchuanfukuanjietu')}}
					</div>
					<van-uploader 
						v-model="fileList" 
						multiple 
						:max-count="1" 
						:after-read="afterRead"
						:accept="'image/jpeg, image/png'"
					/>


				</div>
				
			</div>
			<div class="btn-space">
				<button @click="onSumot" class="queren van-button van-button--primary van-button--normal van-button--block">
					<div class="van-button__content">
						<span class="van-button__text">{{$t('queren')}}</span>
					</div>
				</button>
			</div>
		</div>





	</div>
</template>

<script>
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		apiUserInfo,
		apiRechargeBuy,
		
		apiGetRechargeBank,
		apiGetRechargeBankInfo,
		
		apiUploadFile
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				userInfo: {},
				fileList: [],
				bankInfo: {},
				priceValue: '',
				image: ''
			}
		},
		computed: {
			...mapState(['configInfo'])
		},
		created() {
			this.priceValue = this.$route.query.price
			this.getRechargeBankInfo()
			this.getUserInfo();
		},
		methods: {
			afterRead(file) {
			  file.status = 'uploading';
			  // file.message = this.$t('shangchuanzhong');
			  
			  const formData = new FormData();
			  formData.append('file', file.file);
			  
			  axios.post(this.$baseUrl + 'api/upload/uploadFile', formData, {
				  headers: {
					'Content-Type': 'multipart/form-data',
				  },
				}).then(response => {
				  // 处理服务器响应
				  file.status = 'success';
				  console.log(response.data.data);
				  
				  this.image = response.data.data.storage;
				}).catch(error => {
				  // 处理错误
				  file.status = 'failed';
				  // file.message = '上传失败';
				  
				});
			  
			 //  setTimeout(() => {
				// file.status = 'failed';
				// file.message = '上传失败';
			 //  }, 1000);
			},
			onSumot() {
				if (this.priceValue <= 0) {
					this.$toast(this.$t('qingshurujine'));
					return false;
				}
				
				if (!this.image) {
					this.$toast(this.$t('shangchuanfukuanjietu'));
					return false;
				}
				
				
				apiRechargeBuy({price: this.priceValue, images: this.image}).then(res => {
					if (res.code != 0) {
						return this.$toast(res.msg);
					}
					// location.href = res.data.paymentUrl;
					this.$router.push('/cunk-record');
				})
				
			},
			getRechargeBankInfo() {
				apiGetRechargeBankInfo({
					id: this.$route.query.id
				}).then(res => {
					this.bankInfo = res.data;
				})
			},
			onBankValueClick(item) {
				this.bankValue = item.id;
			},
			getUserInfo() {
				apiUserInfo().then(res => {
					this.userInfo = res.data;
				})
			},
			onPriceClick(index) {
				this.priceValue = this.configInfo.ExpressAmountList[index].price;
			},
			onCoypBtnClick(val) {
				this.$copyText(val).then(()=>{
					this.$toast(this.$t('fuzhichengg'));
				}).catch(()=>{
					this.$toast(this.$t('fuzhishibai'));
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
				this.$router.push('/user-recharge-instructions')
			}
		}
	}
</script>

<style scoped>
	.van-field__control:disabled {
	    color: #333;
	    cursor: not-allowed;
	    opacity: 1;
	    -webkit-text-fill-color: #333;
	}
	.input-text {
		height:.96618rem;
		line-height:.96618rem;
		padding:0 .24155rem;
		width:100%;
		outline:none;
		font-size:.33816rem;
		border:0;
		border-radius:.24155rem;
		margin-top:0;
		background-color:#fff!important
	}
	.tk-wx {
		margin:.36232rem auto
	}
	.tk-wx canvas,.tk-wx img {
		width:4.34783rem;
		margin:0 auto;
		border:.02415rem solid #000;
		padding:.24155rem;
		border-radius:.12077rem
	}
	.pay-panel {
		background:#fff;
		min-height:calc(100vh - 1.44928rem);
		padding:.60386rem .60386rem 0;
		border-radius:.72464rem .72464rem 0 0
	}
	.p-cell .van-button {
		background:0 0;
		color:#2a58e5;
		font-size:.38647rem
	}
	.p-label {
		font-size:.36232rem;
		color:#7a869a;
		margin-top:.36232rem;
		margin-bottom:.12077rem
	}
	.van-tab--active .tk-tab-item {
		color:#fff
	}
	.dep-panel .tri-cell {
		color:#000;
		font-size:.33816rem
	}
	.rs-row {
		display:block;
		padding:0;
		position:relative;
		margin-top:.48309rem
	}
	.leb {
		color:#fff;
		font-family:SFProText-Semibold;
		font-size:.36232rem;
		margin-bottom:.12077rem
	}
	.rs-row:after {
		border-bottom:0
	}
	.pix .van-cell {
		border-radius:.24155rem
	}
	.queren {
		color:#000;
		width:100%;
		height:.96618rem;
		border-radius:.24155rem;
		border:none;
		background-color:#ffc100
	}
	.rg-list .van-cell {
		background-color:transparent
	}
	.van-cell-group {
		box-shadow:unset
	}
	 .van-cell:after {
		border:.02415rem solid #fff
	}
	.van-hairline--top-bottom:after,.van-hairline-unset--top-bottom:after {
		border:none
	}
	.tri-box {
		width:2.6rem
	}
	.van-cell-group {
		background-color:transparent
	}
	.cart {
		background:linear-gradient(180deg,#fff2c8,#ffdfad 47%,#ffd16a);
		box-shadow:0 .12077rem .43478rem 0 rgba(0,0,0,.1);
		border-radius:.43478rem;
		font-size:.31401rem;
		padding:.33816rem .43478rem
	}
	.c-top {
		display:flex;
		align-items:flex-end
	}
	.c-top span:first-child {
		font-size:.70048rem;
		line-height:1em;
		padding-right:.12077rem
	}
	.c-bottom {
		text-align:left
	}
	.input2 .van-cell {
		margin-top:.70048rem;
		background-color:#e1e1df;
		text-align:left
	}
	 .pwa .van-field__label {
		color:#6d6d6d;
		width:90%
	}
	 input::placeholder {
		color:#ccc
	}
	 .van-uploader__upload {
		border-radius:.16908rem
	}
	 .van-uploader__upload-icon {
		color:#ffd300;
		font-size:.67633rem
	}
	.uploader-box {
		display:flex;
		margin-top:.33816rem
	}
	.btn {
		font-weight:700;
		font-size:.33816rem
	}
	.cb1 {
		color:#ffc100
	}
	
	
	.cory {
		color:#ffc100
	}
	
</style>
