<template>
	<div class="index-page">
		<router-view />
		<div>
			<van-tabbar v-model="navActive" :safe-area-inset-bottom="true">
			  <van-tabbar-item to="/">
				  <div class="iconfont icon-foot1"></div>
				  <div class="item-label">{{$t('zhuye')}}</div>
			  </van-tabbar-item>
			  <van-tabbar-item to="/activity">
				  <van-icon name="point-gift" size=".6rem" :color=" navActive == 1 ? '#000': '#a8bab4'"/>
				
				  <!-- <div class="iconfont icon-jiaoyimingxi"></div> -->
				  <div class="item-label">{{$t('huodong')}}</div>
			  </van-tabbar-item>
			  <van-tabbar-item to="/robot-run-page">
				  <div class="iconfont icon-foot2"></div>
				  <div class="item-label">{{$t('hangqing')}}</div>
			  </van-tabbar-item>
			  <van-tabbar-item to="/uc-team">
				  <div class="iconfont icon-foot3"></div>
				  <div class="item-label">{{$t('tuandui')}}</div>
			  </van-tabbar-item>
			  <van-tabbar-item to="/ucenter">
				  <div class="iconfont icon-foot4"></div>
				  <div class="item-label">{{$t('zizhan')}}</div>
			  </van-tabbar-item>
			</van-tabbar>
			
		</div>


	</div>
</template>

<script>
	import 'swiper/dist/js/swiper'
	import 'swiper/dist/css/swiper.css'
	import Swiper from "swiper"
	export default {
		name: 'index',
		data() {
			return {
				purchaseContractShow:false,
				contractDescriptionShow: false,
				navActive: 0,
				priceValue: 1
			}
		},
		created() {
			
		},
		activated() {
			console.log('this.$route.name',this.$route.name)
		},
		watch: {
		   '$route':'luyou'
		},
		mounted() {
			this.$nextTick(() => {
				this.myswiper()
			})
			
			 this.luyou();
		},
		methods: {
			luyou() {
				switch(this.$route.name){
					case '/' :  this.navActive = 0;break;
					case 'activity' :  this.navActive = 1;break;
					case 'robot-run-page' :  this.navActive = 2;break;
					case 'uc-team' :  this.navActive = 3;break;
					case 'ucenter' :  this.navActive = 4;break;
					default : this.navActive = 0;
				 }
			},
			myswiper() {
				var swiper = new Swiper('.swiper-container1', {
					slidesPerView: 3,
					pagination: {
						el: '.sw-pagination',
						clickable: true, // 分页器可以点击
					},
					paginationClickable: true,
					observer: true, //修改swiper自己或子元素时，自动初始化swiper
					observeParents: true, //修改swiper的父元素时，自动初始化swiper
				});
				var swiper2 = new Swiper('.swiper-container2', {
					// slidesPerView:3,
					slidesPerView: "auto",
				});
			},
			onMessageListUrl() {
				this.$router.push('/message-list');
			},
			onPurchaseContract() {
				this.purchaseContractShow = !this.purchaseContractShow;
			},
			onPurchaseContractClear() {
				this.purchaseContractShow = !this.purchaseContractShow;
			},
			onContractDescription() {
				this.contractDescriptionShow = !this.contractDescriptionShow;
				
				this.$refs.contractSwipe.swipeTo(0);
			},
			onContractDescriptionChange(index) {
				console.log(index, this.contractImages.length)
				if (index == this.contractImages.length-1) {
					this.contractDescriptionShow = !this.contractDescriptionShow;
				}
			},
			onRobotUrl() {
				this.$router.push('/robot')
			},
			onTeamUrl() {
				this.$router.push('/team')
			}
		}
	}
</script>

<style scoped>
	.van-icon {
		margin-top: 4px;
	}
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.logo-cell .desc {
		color: #333;
	}
	.page-popup {
	    background-color: transparent;
	}
	.index-main {
		margin-bottom: 1.48309rem;
	}
	.team-wrapper {
		margin-bottom: 1.48309rem;
	}
	.index-page {
		margin-bottom: 1.48309rem;
	}
</style>
