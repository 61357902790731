<template>
	<div class="loading">
		<div id="loader-wrapper">
			<div id="loader">
				<img v-if="configInfo" :src="baseUrl + configInfo.logoImg">
			</div>
		</div>
	<!-- 	<van-loading type="spinner" color="#4994df">
			 <span style="color:#4994df">{{ title || '加载中···' }}</span>
		</van-loading> -->
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		name: 'Loading',
		data() {
			return {
				baseUrl: this.$baseUrl,
				title: '',
				configData:{},
			}
		},
		props: {
		  msg: String
		},
		computed: {
			...mapState(['loading', 'configInfo'])
		},
		created() {
		},
		methods: {
		}
	}
</script>

<style scoped>
	.loading {
	    position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	    z-index: 9999;
	    width: 100%;
	    height: 100%;
	    background: #ffd300;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	  }
	  #loader img {
		  width: 4rem;
	  }
</style>
