<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('wodejiqiren')" left-arrow :fixed="true" :placeholder="true" @click-left="onClickLeft" @click-right="onClickRight" />
		<div class="tabs-wrapper" style="font-size: 16px;">
			
			<van-tabs class="ui-tabs" v-model="active">
			  <van-tab :title="$t('wodejiqiren')">
				  
				  <div class="robot-list" style="padding-top: 20px;">
				  	<div :key="index" v-if="item.status == 1" v-for="(item,index) in orderlist" style="margin-bottom: 10px; background-color: rgb(229, 195, 41); border-radius: 10px;">
				  		<div class="ro-list-box">
				  			<div class="ro-heading flex-center"
				  				style="justify-content: space-between; background-color: rgb(193, 164, 34);">
				  				<div class="flex-center">
				  					<img src="@/static/img/trlogo2.0ec18c76.png" class="ro-logo">
				  					<span class="tt">{{item.title}}</span>
				  				</div>
				  				<div style="color: rgb(255, 255, 255);">
				  					<span>{{item.status == 1 ? $t('zaigongzuo') : $t('yimaichu')}}</span>
				  				</div>
				  			</div>
				  			<div class="ro-body">
				  			<!-- 	<div class="rob-info">
				  					<span class="rlabel">{{$t('zongyunyingzijin')}}：</span>30.00
				  					<span style="font-size: 12px;">{{$currencyUnit}}</span>
				  				</div> -->
				  
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('laiyuan')}}：</span>{{$t('hetonggoumaai')}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('jiage')}}：</span>{{item.price}}
				  					<span style="font-size: 12px;">{{$currencyUnit}}</span>
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('youxiaoqi')}}：</span>{{item.timelimit}} {{$t('tian')}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('yujirishouru')}}：</span>{{item.dailyaverageAll}} {{$currencyUnit}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('huoqushijian')}}：</span>{{item.create_time}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('zuihoujihuoshijian')}}：</span>{{item.end_time}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('jingguoshijian')}}：</span>{{item.jgTime}}
				  				</div>
				  
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('weiyidaima')}}：</span>{{item.order_sn}}
				  				</div>
				  			</div>
				  			<div class="rob-card-foot flex-center" style="justify-content: right;">
				  
				  				<!-- <button
				  					class="btn van-button van-button--default van-button--normal van-button--round"
				  					style="background-color: rgb(55, 171, 31); color: rgb(255, 255, 255);">
				  					<div class="van-button__content">
				  						<span class="van-button__text">暂停</span>
				  					</div>
				  				</button> -->
				  			</div>
				  		</div>
				  	</div>
			
				  </div>
				  					
				  
			  </van-tab>
			  <van-tab :title="$t('yimaichujiqiren')">
				  
				  <div class="robot-list" style="padding-top: 20px;">
				  	<div :key="index" v-if="item.status == 2" v-for="(item,index) in orderlist" style="margin-bottom: 10px; background-color: rgb(229, 195, 41); border-radius: 10px;">
				  		<div class="ro-list-box">
				  			<div class="ro-heading flex-center"
				  				style="justify-content: space-between; background-color: rgb(193, 164, 34);">
				  				<div class="flex-center">
				  					<img src="@/static/img/trlogo2.0ec18c76.png" class="ro-logo">
				  					<span class="tt">{{item.title}}</span>
				  				</div>
				  				<div style="color: rgb(255, 255, 255);">
				  					<span>{{item.status == 1 ? $t('zaigongzuo') : $t('yimaichu')}}</span>
				  				</div>
				  			</div>
				  			<div class="ro-body">
				  			<!-- 	<div class="rob-info">
				  					<span class="rlabel">{{$t('zongyunyingzijin')}}：</span>30.00
				  					<span style="font-size: 12px;">{{$currencyUnit}}</span>
				  				</div>
				  -->
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('laiyuan')}}：</span>{{$t('hetonggoumaai')}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('jiage')}}：</span>{{item.price}}
				  					<span style="font-size: 12px;">{{$currencyUnit}}</span>
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('youxiaoqi')}}：</span>{{item.timelimit}} {{$t('tian')}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('yujirishouru')}}：</span>{{item.dailyaverageAll}} {{$currencyUnit}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('huoqushijian')}}：</span>{{item.create_time}}
				  				</div>
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('zuihoujihuoshijian')}}：</span>{{item.end_time}}
				  				</div>
				  		<!-- 		<div class="rob-item">
				  					<span class="rlabel">{{$t('jingguoshijian')}}：</span>{{item.jgTime}}
				  				</div>
				  -->
				  				<div class="rob-item">
				  					<span class="rlabel">{{$t('weiyidaima')}}：</span>{{item.order_sn}}
				  				</div>
				  			</div>
				  			<div class="rob-card-foot flex-center" style="justify-content: right;">
				  
				  				<!-- <button
				  					class="btn van-button van-button--default van-button--normal van-button--round"
				  					style="background-color: rgb(55, 171, 31); color: rgb(255, 255, 255);">
				  					<div class="van-button__content">
				  						<span class="van-button__text">暂停</span>
				  					</div>
				  				</button> -->
				  			</div>
				  		</div>
				  	</div>
				  			
				  
				  </div>
				  
			  </van-tab>
			</van-tabs>
			
		
		</div>
	</div>
</template>

<script>
	
	import {
		apiFundOrderList
	} from '@/request/api'; //api接口
	
	export default {
		data() {
			return {
				active:0,
				orderlist: []
			}
		},
		created() {
			this.getFundOrderList();
		},
		methods: {
			getFundOrderList() {
				apiFundOrderList().then(res => {
					if (res.code!=0) {
						return this.$toast(res.msg);
					}
					res.data.forEach((item,index) => {
						let times = item.timedifference;
						let t = parseInt(times /60/60/24);
						let h = parseInt(times / 60 / 60 % 24);
						let m = parseInt(times / 60 % 60);
						let s = parseInt(times % 60);
						
						item.jgTime = t + this.$t('tian') + h + this.$t('shi') + m +this.$t('fen');
					})
					this.orderlist = res.data;
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style scoped>
	.rob-card-foot .van-button {
		height:.72464rem;
		border:unset;
		margin-right:.24155rem
	}
	.dv-input-row .van-field__label {
		width:unset
	}
	.rob-card-foot {
		padding:0 .24155rem .24155rem
	}
	.rob-info {
		color:#fff
	}
	.rob-item {
		font-weight:700
	}
	.rob-info,.rob-item {
		font-size:.33816rem;
		padding:.12077rem
	}
	.rlabel {
		opacity:.7;
		font-weight:400
	}
	.s-slider {
		height:.36232rem;
		background-color:#d18e37;
		border:.02415rem;
		box-shadow:0 0 0 .09662rem #d18e37
	}
	.s-slider .van-slider__bar {
		background-color:#ffcb29
	}
	.robot-btn {
		width:3.62319rem!important;
		margin:0 .24155rem
	}
	.ui-tabs .van-dropdown-menu__bar {
		background-color:unset;
		box-shadow:unset
	}
	.ui-tabs .van-dropdown-menu__title {
		color:#646566;
		width:100%;
		padding-right:.36232rem
	}
	.ui-tabs .van-tab--active .van-dropdown-menu__title {
		color:#fff!important;
		width:100%;
		padding-right:.36232rem
	}
	.ui-tabs .van-tab--active {
		color:#fff!important
	}
	.ui-tabs .van-dropdown-menu__title--active {
		color:#d5ff89!important
	}
	.ui-tabs .van-tab__text--ellipsis {
		padding:0
	}
	.ui-tabs .van-dropdown-menu__title:after {
		right:.12077rem
	}
	.ui-tabs .van-dropdown-item__content {
		background-color:#fff
	}
	.ui-tabs .van-cell__title span {
		color:#ffc100
	}

</style>
