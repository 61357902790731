<template>
	<div class="page-help" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
	  <van-nav-bar :title="$t('bangzhuzhongxin')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />

	  <div class="wrapper">
	    <div class="wt-card">
	      <div class="help-tt">{{$t('chuangjianwenti')}}
	      </div>
	      <div class="help-collapse van-collapse van-hairline--top-bottom">
	        <div class="van-collapse-item">
	          <div role="button" tabindex="0" aria-expanded="true" class="van-cell van-cell--clickable van-collapse-item__title van-collapse-item__title--expanded">
	            <div class="van-cell__title">
	              <div class="h-title" style="padding: 2px 5px;">
	               Q：{{$t('help.p1')}}
	              </div>
	            </div>
	            <i class="van-icon van-icon-arrow van-cell__right-icon">
	              </i>
	          </div>
	          <div class="van-collapse-item__wrapper">
	            <div class="van-collapse-item__content">
	              <div class="h-content">
	                <div class="flex_bd">
	                  <p v-html="$t('help.p1_1')"></p>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	        <div class="van-collapse-item van-collapse-item--border">
	          <div role="button" tabindex="0" aria-expanded="true" class="van-cell van-cell--clickable van-collapse-item__title van-collapse-item__title--expanded">
	            <div class="van-cell__title">
	              <div class="h-title" style="padding: 2px 5px;">
	                Q：{{$t('help.p2')}}
	              </div>
	            </div>
	            <i class="van-icon van-icon-arrow van-cell__right-icon">
	              </i>
	          </div>
	          <div class="van-collapse-item__wrapper" style="">
	            <div class="van-collapse-item__content">
	              <div class="h-content">
	                <div class="flex_bd">
	                  <p v-html="$t('help.p2_1')">
	                  </p>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	        <div class="van-collapse-item van-collapse-item--border">
	          <div role="button" tabindex="0" aria-expanded="true" class="van-cell van-cell--clickable van-collapse-item__title van-collapse-item__title--expanded">
	            <div class="van-cell__title">
	              <div class="h-title" style="padding: 2px 5px;">
	                Q：{{$t('help.p3')}}
	              </div>
	            </div>
	            <i class="van-icon van-icon-arrow van-cell__right-icon">
	              </i>
	          </div>
	          <div class="van-collapse-item__wrapper" style="">
	            <div class="van-collapse-item__content">
	              <div class="h-content">
	                <div class="flex_bd">
	                  <p v-html="$t('help.p3_1')"></p>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
	
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style scoped>
	.van-cell {
		font-size: 12px;
	}
</style>