<template>
	<div class="page">
		<van-nav-bar :title="$t('guanyuwomen')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />

		<div class="info">
			<p>{{$t('about.p1')}}</p>
			<p>{{$t('about.p2')}}</p>
			<p>{{$t('about.p3')}}</p>
			<p>{{$t('about.p4')}}</p>
			<p>{{$t('about.p5')}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {

			}
		}
	}
</script>

<style scoped>
	.info {
		padding: .4rem;
		color: #333;
		font-size: .3rem;
	}
	.info p {
		margin-bottom: .3rem;
	}
</style>
