<template>
	<div class="page">
		<van-nav-bar :title="$t('xiaoxixiangqing')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
		<div class="wrapper">
			<div class="mes-panel">
				<div class="tri-cell" style="font-size: 14px;">
					<div class="mes-content" style="text-align: left; padding: 10px 0px; color: rgb(122, 134, 154);">
					{{infoData.descs}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		apiUserMessageDetail
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				infoData: {}
			}
		},
		created() {
			this.getUserMessageDetail();
		},
		methods: {
			getUserMessageDetail(){
				apiUserMessageDetail({id: this.$route.query.id}).then(res => {
					this.infoData = res.data;
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style scoped>
</style>
