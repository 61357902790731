import Vue from 'vue'
import Vuex from 'vuex'
import { apiUserLogin,apiConfigData } from '@/request/api';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		usdtContractAddress: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t', // TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6tUSDT
		loading: false,
		kefuShow: false,
		inviter_code: '',
		walletAddress: '',
		user: null,
		configInfo: null
	},
	getters: {},
	mutations: {
		setWallAddress(state, val) {
			state.walletAddress = val
		},
		setUser(state, val) {
			state.user = val
		},
		setConfigInfo(state,val) {
			state.configInfo = val
		},
		showLoading(state) {
		  state.loading = true
		},
		setKefuShow(state) {
		  state.kefuShow = true
		},
		setKefuHide(state) {
		  state.kefuShow = false
		},
		hideLoading(state) {
		  state.loading = false
		}
	},
	actions: {
		async isLogin(ctx) {
			this.tronWeb = window.tronWeb;
			let defaultAddress = window.tronWeb.defaultAddress.base58 ? window.tronWeb
				.defaultAddress.base58 :
				''
			ctx.commit('setWallAddress', defaultAddress);
		},
		async getConfigInfo(ctx) {
			apiConfigData().then(res => {
				ctx.commit('setConfigInfo', res.data);
			})
		},
		async login(ctx, msg) {
			//登陆状态设置为true
			// let Inval = null;
			// clearInterval(Inval);
			// Inval = setInterval(async () => {
			// 	this.tronWeb = window.tronWeb;
			// 	let defaultAddress = window.tronWeb.defaultAddress.base58 ? window.tronWeb
			// 		.defaultAddress.base58 :
			// 		''
			// 	ctx.commit('setWallAddress', defaultAddress);
			
			// 	let inviter_code = localStorage.getItem("inviter_code");
			// 	apiUserLogin({address:defaultAddress, code: inviter_code}).then(res=>{});
			// 	let contract = await tronWeb.contract().at(this.state.usdtContractAddress);
			// 	let balance = await tronWeb.trx.getBalance(defaultAddress);
			// 	let trxtotala = balance / (10 ** 6);
			// 	console.log('balance', trxtotala, defaultAddress)
			// 	let result = await contract.balanceOf(defaultAddress).call((err, tex) => {
			// 		if (err == null) {
			// 			let total = tex._hex / (10 ** 6);


			// 			console.log(parseInt(total) + ' USDT');

			// 			ctx.commit('setUser', {
			// 				trx: parseInt(trxtotala),
			// 				usdt: parseInt(total)
			// 			});
			// 		} else {
			// 			console.log(err);
			// 		}
			// 	});
				
			// 	if (defaultAddress) {
			// 		window.clearInterval(Inval);
			// 	}
			// }, 1000);
		},
		async getBalance(ctx, val) {
			this.tronWeb = window.tronWeb;
			////////TRC20查余额
			let contract = await tronWeb.contract().at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
			let result = await contract.balanceOf(ctx.walletAddress).call(function(err, tex) {
				if (err == null) {
					let total = tex._hex / (10 ** 6);
					var balance = this.tronWeb.trx.getBalance(ctx.walletAddress);

					console.log(total.toLocaleString() + ' USDT');

					ctx.commit('setUser', {
						trx: balance,
						usdt: total.toLocaleString()
					});
				} else {
					console.log(err);
				}
			});
		}
	},
	modules: {}
})
