import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import axios from 'axios';
import $mConfig from '@/config/index.js';
import { Lazyload,Toast } from 'vant';
import VueClipboard from 'vue-clipboard2'
import 'lib-flexible/flexible'
import store from './store'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n'
import global from './static/js/global.js'
Vue.prototype.$global = global
Vue.prototype.$axios = axios
import 'element-ui/lib/theme-chalk/index.css';
// import 'swiper/css/swiper.css';

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = $mConfig.baseUrl;
Vue.prototype.$currencyUnit = $mConfig.currencyUnit;
import {
		apiGetIslogin
	} from '@/request/api';

// console.log(this.$store.state.configInfo);


Vue.use(VueI18n);
Vue.use(Toast);
Vue.use(VueClipboard)
Vue.use(Vant);
Vue.use(Lazyload)
Vue.use(ElementUI);
// localStorage.setItem('lang', 'zh');
const i18n = new VueI18n({
  // locale: localStorage.lang || 'lt',
  // locale: 'en',
  locale: 'th',
  messages: {
    'zh': require('./i18n/lang/zh'),
    'en': require('./i18n/lang/en'),
	'lt': require('./i18n/lang/lt'),
	'th': require('./i18n/lang/th')
  }
})



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
