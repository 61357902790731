<template>
	<div class="page">
	  <van-nav-bar :title="$t('chunkuanshuoming')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
	  <div style="font-size: 14px;">
	    <p>{{$t('inst.t')}}
	      <br>Step 1：{{$t('inst.p1')}}
	      <br>
	      <br>Step 2：{{$t('inst.p2')}}
	      <br>
	      <br>Step 3：{{$t('inst.p3')}}
	      <br>
	      <br>Step 4：{{$t('inst.p4')}}
	      <br>
	      <br>{{$t('inst.p5')}}
	    </p>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
	
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style>
</style>