<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
	  
	  <van-nav-bar 
		:title="$t('wodeGcash')" 
		:fixed="true" 
		:placeholder="true" left-arrow @click-left="onClickLeft"
	  	@click-right="onClickRight" />
	  
	  <div class="wrapper">
	    <form class="bs-form van-form">
	      <div role="button" tabindex="0" class="van-cell van-cell--clickable van-field">
	        <div class="van-cell__title van-field__label" style="width: 40%;">
	          <span class="text-green">{{$t('kaisheyinhangzhanghu')}}</span>
	        </div>
	        <div class="van-cell__value van-field__value">
	          <div class="van-field__body">
	            <input type="text" name="picker" 
				@click="show = true" 
				v-model="formData.bank_name" 
				readonly="readonly"
				:placeholder="$t('qingxuanzeyinhangka')"
				class="van-field__control"></div>
	        </div>
	      </div>
	      <div class="van-cell van-field">
	        <div class="van-cell__title van-field__label">
	          <span class="text-green">{{$t('zhanghumingcheng')}}</span>
	        </div>
	        <div class="van-cell__value van-field__value">
	          <div class="van-field__body">
	            <input type="text"  v-model="formData.account_name" :placeholder="$t('zhanghumingcheng')" class="van-field__control"></div>
	        </div>
	      </div>
		  <div class="van-cell van-field">
		    <div class="van-cell__title van-field__label">
		      <span class="text-green">{{$t('kahao')}}</span>
		    </div>
		    <div class="van-cell__value van-field__value">
		      <div class="van-field__body">
		        <input type="text"  v-model="formData.bank_num" :placeholder="$t('kahao')" class="van-field__control"></div>
		    </div>
		  </div>
		  <div class="van-cell van-field">
		    <div class="van-cell__title van-field__label">
		      <span class="text-green">{{$t('lianxidianhua')}}</span>
		    </div>
		    <div class="van-cell__value van-field__value">
		      <div class="van-field__body">
		        <input type="text"  v-model="formData.customer_mobile" :placeholder="$t('lianxidianhua')" class="van-field__control"></div>
		    </div>
		  </div>
	      
	      <!-- <div class="van-cell" style="color: rgb(102, 102, 102);">请绑定正确的提现信息</div> -->
	    </form>
	    <div class="btn-space btn-fixed">
	    
		  <button @click="saveInfo" class="queren van-button van-button--primary van-button--normal van-button--block">
		  	<div class="van-button__content">
		  		<span class="van-button__text">{{$t('tijiao')}}</span>
		  	</div>
		  </button>
	    </div>
		
		
		
	  </div>
		
		
	  <van-action-sheet 
		  v-model:show="show" 
		  :description="$t('xuanzeyanhang')" 
		  :cancel-text="$t('quxiao')" 
		  :actions="actions" 
		  @select="onSelect" />
	</div>
</template>

<script>
	
	import {
		apiUserInfo,
		apiGetWithdrawBankList,
		apiUserSaveBackInfo,
		apiGetUserBankInfo
	} from '@/request/api'; //api接口
	
	export default {
		data() {
			return {
				show: false,
				actions: [],
				bankValue: {},
				formData: {
					id: '',
					bank_name: '',
					account_name: '',
					bank_num: '',
					customer_mobile: ''
				},
				userInfo:{},
				showLoading: null
			}
		},
		created() {
			
			this.showLoading = this.$toast.loading({
			  message: this.$t('loadingText'),
			  forbidClick: true,
			  loadingType: 'spinner',
			  duration: 0
			});
			this.getUserBankInfo();
			this.getUserInfo()
			this.getWithdrawBankList();
		},
		methods: {
			getUserInfo() {
				apiUserInfo().then(res => {
					this.userInfo = res.data;
					this.showLoading.close();
					
					
				})
			},
			getUserBankInfo() {
				apiGetUserBankInfo({
					id: this.$route.query.id
				}).then(res => {
					if (res.data) {
						this.formData.id = res.data.id;
						this.formData.bank_name = res.data.bank_name;
						this.formData.account_name = res.data.account_name;
						this.formData.bank_num = res.data.bank_num;
						this.formData.customer_mobile = res.data.customer_mobile;
					}
				})
			},
			
			onSelect(val, index) {
				this.show = false;
				this.formData.bank_name = this.actions[index].name;
		    },
			
			getWithdrawBankList() {
				apiGetWithdrawBankList().then(res => {
					this.actions = res.data;
				})
			}, 
			saveInfo() {
				
				if (!this.formData.bank_name) {
					this.$toast(this.$t("qingxuanzeyinhangka"));
					return false;
				}
				
				if (!this.formData.account_name) {
					this.$toast(this.$t("qingshuruyinhangmingcheng"));
					return false;
				}
				if (!this.formData.bank_num) {
					this.$toast(this.$t("qingshuruyinhangkahao"));
					return false;
				}
				if (!this.formData.customer_mobile) {
					this.$toast(this.$t("qingshurulianxidianhua"));
					return false;
				}
				
				apiUserSaveBackInfo(this.formData).then(res => {
					
					console.log(res);
					this.$toast(res.msg);
					if (res.code == 0) {
						setTimeout(() => {
							this.$router.push('/withdrawal')
						}, 2000)
						this.getUserInfo()
					} 
				})
		
			  },
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
			}
		}
	}
</script>

<style scoped>
	.queren {
		color:#000;
		width:100%;
		height:.96618rem;
		border-radius:.24155rem;
		border:none;
		background-color:#ffc100
	}
</style>