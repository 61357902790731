<template>
	<div class="page-green">
		<div class="form-page">
			<div class="langBtn" v-if="configInfo && configInfo.MultiLingua == '1'" @click="langClick">{{$t('xuanzeyuyan')}}</div>
			<div class="logo" style="padding-top: 80px;">
				<div class="form-heading">
					<img  v-if="configData.logoImg" :src="baseUrl + configData.logoImg">
					<div class="text">{{configData.websiteName}}</div>
				</div>
			</div>
			<div class="wrapper">
				<div class="bs-form van-form">
					<div class="field-phone van-cell van-field">
						<div class="van-cell__title van-field__label">
							<span>{{$t('user.yonghuming')}}</span>
						</div>
						<div class="van-cell__value van-field__value">
							<div class="van-field__body">
								<div class="van-field__body">
									<input type="text" v-model="fromData.username" :placeholder="$t('user.qingshurnindeyonghuming')"
										class="van-field__control">
								</div>
				
							</div>
						</div>
					</div>
					<div class="van-cell van-field">
						<div class="van-cell__title van-field__label">
							<span>{{$t('user.mima')}}</span>
						</div>
						<div class="van-cell__value van-field__value">
							<div class="van-field__body">
								<input type="password" autocomplete v-model="fromData.password" :placeholder="$t('user.qingshumima')" class="van-field__control">
							</div>
						</div>
					</div>
					<div class="btn-space">
						<van-button class="van-button--submit van-button--block" @click="onLogin">{{$t('user.denglu')}}</van-button>
					</div>
				</div>
			</div>
			<div class="form-link">
				<div>
					<!-- 忘记密码了吗？
					<a href="#/find-pwd" class="">忘记？</a> -->
					<br>{{$t('user.haimeizhanghu')}}？
					<a href="#/regist" class="">{{$t('dengji')}}</a>
				</div>
			</div>
		</div>
		
		<van-popup v-model="SwYuyanShow" round position="bottom" :style="{ height: '30%' }">
			<van-picker
			  show-toolbar
			  :columns="columns"
			  @confirm="onConfirm"
			  @cancel="onCancel"
			  @change="onChange"
			/>
		</van-popup>
		
		<van-action-sheet v-model="langShow"
		:actions="langArr" 
		:cancel-text="$t('quxiao')" 
		close-on-click-action 
		@select="onlangSelect"/>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		apiLoginLogin,
		apiConfigData
	} from '@/request/api'; //api接口
	import QS from 'qs';
	export default {
		data() {
			return {
				langShow: false,
				langArr: [
				  // {
				  //   name: this.$t('lang_Zh'),
				  //   val: 'zh'
				  // },
				  {
				    name: this.$t('lang_Lt'),
				    val: 'lt'
				  },
				  {
				    name: this.$t('lang_En'),
				    val: 'en'
				  }
				],
				baseUrl: this.$baseUrl,
				SwYuyanShow: false,
				columns: ['Philippines +63', 'United States +1', 'United Kingdom +44', 'Poland (Polska) +48'],
				fromData: {
					username: '',
					password: ''
				},
				configData: {}
			}
		},
		computed: {
			...mapState(['loading', 'configInfo'])
		},
		created() {
			this.getConfigData()
		},
		methods: {
			getConfigData() {
				apiConfigData().then(res => {
					this.configData = res.data;
				})
			},
			langClick() {
			  this.langShow = true;
			},
			onlangSelect(item) {
			  localStorage.setItem('lang',item.val);
			  this.$i18n.locale = item.val;
			  this.langArr.forEach((item,index) => {
				  if (item.val == 'zh') {
					  this.langArr[index]['name'] = this.$t('lang_Zh');
				  } else if (item.val == 'lt') {
					  this.langArr[index]['name'] = this.$t('lang_Lt');
				  } else if (item.val == 'en') {
					  this.langArr[index]['name'] = this.$t('lang_En');
				  }
			  }) 
			},
			onLogin( ){
				
				if (!this.fromData.username) {
					this.$toast(this.$t('user.qingshurnindeyonghuming'));
					return false;
				}
				if (!this.fromData.password) {
					this.$toast(this.$t('user.qingshumima'));
					return false;
				}
				this.$axios.post(this.$baseUrl + 'api/user/login', QS.stringify(this.fromData)).then(res=>{
					if (res.data.code != 0) {
						this.$toast(res.data.msg);
						return false;
					}
					localStorage.setItem('token',res.data.data.token);
					this.$router.push('/');
				})
				// apiLoginLogin(this.fromData).then(res=>{
				// 	if (res.code != 0) {
				// 		this.$toast(res.msg);
				// 		return false;
				// 	}
					
				// 	localStorage.setItem('token',res.data.token);
				// 	this.$router.push('/');
				// })
			},
			onConfirm(value, index) {
			},
			onChange(picker, value, index) {
			},
			onCancel() {
			  this.SwYuyanShow =!this.SwYuyanShow;
			  
			},
		}
	}
</script>

<style scoped>
	.text {
		color: #a8a8a8
	}

	.page-green {
		position: relative;
		background-image: url(@/static/img/login_bg.bf304ab3.png);
		background-size: 100% 100%
	}
	.langBtn {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: 14px;
		color: #333;
		/* color: #ffd300; */
	}

	.register {
		width: 100%;
		position: absolute;
		bottom: 1.20773rem
	}

	.van-button__text {
		color: #000
	}

	.bs-form .van-cell {
		background-color: #f1f2f4
	}

	.xh-val {
		color: #333
	}

	.van-icon {
		background: 0 0
	}

	.rigth {
		height: .86957rem;
		line-height: .86957rem;
		overflow: hidden
	}

	.icon-box {
		width: .86957rem;
		height: 100%;
		margin-right: .12077rem
	}

	.icon {
		width: 100%
	}
	
	.van-field__body {
		width: 100%;
	}
</style>
