<template>
	<div class="page-wt">
		<van-nav-bar title="USDT API" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft"
			@click-right="onClickRight" />
		
		<div class="main">
			<div class="content">
				<div class="hint">{{$t('pay.t1')}}
				</div>
				<h2 class="amount parse-action">{{orderInfo.price}}
					<span class="unit">{{$currencyUnit}}</span>
				</h2>
				<div 
					v-clipboard:copy="trxInfo.address"
					v-clipboard:success="firstCopySuccess"
					v-clipboard:error="firstCopyError"
					class="address parse-action">
					{{trxInfo.address}}</div>
				<div class="qrcode">
					<div class="wx-pic" ref="qrCodeUrl"></div>
				</div>
				<div class="timer">
					<ul class="downcount">
						<van-count-down :time="time" />
					</ul>
				</div>
				<div class="divider"></div>
				<ol class="remark">
					<li>{{$t('pay.p1')}}</li>
					<li>{{$t('pay.p2')}}</li>
					<li>{{$t('pay.p3')}}</li>
					<li>{{$t('pay.p4')}}</li>
					<!-- <li>如出现支付金额低于订单金额，同时系统未到账的情况，你可向同一个收款地址再次转入余下的金额，系统会合并多次付款并进行自动处理。</li> -->
				</ol>
				<br>
				<br>
				<br>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		apiGetUsertrx,
		apiGetUserOrderDes,
		apiUpuserusdt
	} from '@/request/api'; //api接口
	import QRCode from 'qrcodejs2'
	
	let timeA = null;
	export default {
		data() {
			return {
				trxInfo: {},
				priceValue: '',
				time: 30 * 60 * 1000,
				orderInfo: {}
			}
		},
		created() {
			this.getUsertrx();
			this.getUserOrderDes();
			this.getUpuserusdt();
			timeA = setInterval(()=> {
				this.getUpuserusdt();
			},3000);
		},
		destroyed() {
			clearInterval(timeA);
		},
		methods: {
			getUserOrderDes() {
				apiGetUserOrderDes({orderNo: this.$route.query.no}).then(res => {
					this.orderInfo = res.data;
				})
			},
			getUpuserusdt() {
				apiUpuserusdt({orderNo: this.$route.query.no}).then(res => {
					// this.$toast(res.msg);
					if (res.code == 0) {
						clearInterval(timeA);
						this.$toast({
							duration: 6000,
							forbidClick: true,
							message: this.$t('chenggongzhifu')+res.data,
						});
						setTimeout(()=>{
							this.$router.push('/ucenter')
						},6000);
					}
				})
			},
			creatQrCode() {
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: this.trxInfo.address, // 待生成为二维码的内容
					width: 240,
					height: 240,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			getUsertrx() {
				apiGetUsertrx().then(res => {
					this.trxInfo = res.data;
					this.creatQrCode()
				})
			},
			firstCopySuccess(e) {
				this.$toast(this.$t('fuzhichengg'));
			},
			firstCopyError(e) {
			  this.$toast(this.$t('fuzhishibai'));
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
				this.$router.push('/team-proxy')
			}
		}
	}
</script>

<style scoped>
	* {
		padding: 0;
		margin: 0
	}
	.page-wt {
	    background-image: url('@/static/img/topbg3.412fc122.png');
	    background-repeat: no-repeat;
	    background-size: contain;
	    background-color: #fff;
	    min-height: 100vh;
	}
	.tipss {
		font-size: 14px;
		width: 100%;
		background: rgb(220, 20, 60);
		opacity: 0.9;
		height: 40px;
		color: rgb(255, 255, 255);
		line-height: 40px;
		text-align: center;
		position: fixed;
		bottom: 0;
		left: 50%;
		margin-left: -200px;
		margin-top: 80px;
		z-index: 9;
		font-weight: bold;
	}

	.container {
		text-align: center;
		background-color: #f8f9fa;
		box-sizing: border-box;
		height: 100%;
		min-height: 100vh;
		background-repeat: repeat;
		overflow-x: hidden
	}

	.header {
		color: #232425;
		font-size: 18px;
		padding: 15px 25px;
		overflow: hidden;
		background-color: #f2f2f2;
		box-sizing: border-box;
		background-image: url('@/static/img/title.png');
		background-repeat: repeat;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)
	}

	.main {
		margin: 25px 15px
	}

	.content {
		padding: 25px;
		width: 100%;
		margin: 0 auto;
		min-width: 320px;
		max-width: 420px;
		background: #fff;
		box-sizing: border-box;
		border-radius: 5px;
		overflow: hidden;
		background: linear-gradient(180deg,#fff2c8,#ffdfad 47%,#ffd16a);
		    box-shadow: 0 0.12077rem 0.43478rem 0 rgb(0 0 0 / 10%);
	}

	.hint {
		color: #676869;
		margin-bottom: 20px
	}

	.amount {
		color: #232425;
		margin-bottom: 20px
	}

	.address {
		padding: 8px 10px;
		font-size: 14px;
		border-radius: 20px;
		margin-bottom: 20px;
		color: #232425;
		display: inline-block;
		background-color: #f8f9fa;
		border: 1px solid #f2f2f2
	}

	.qrcode {
		width: 240px;
		height: 240px;
		margin: 0 auto;
		border: 1px solid #f5f6f7;
		overflow: hidden
	}

	.qrcode img {
		width: 100%;
		border: 0;
		display: block
	}

	.divider {
		width: 100%;
		height: 1px;
		border-top: 1px dashed #f2f2f2;
		margin: 20px 0
	}

	.remark {
		color: #f84242;
		text-align: left;
		margin-left: 20px
	}

	.remark li {
		margin: 10px 0
	}

	.parse-content {
		padding: 0;
		margin: 0;
		height: 0;
		border: 0;
		outline: 0;
		overflow: hidden
	}

	ul.downcount {
		list-style: none;
		margin: 15px 0;
		padding: 0;
		display: block;
		text-align: center
	}

	ul.downcount li {
		display: inline-block
	}

	ul.downcount li span {
		font-size: 24px;
		font-weight: 300;
		line-height: 24px;
		color: #232425
	}

	ul.downcount li.seperator {
		font-size: 24px;
		line-height: 24px;
		vertical-align: top
	}

	ul.downcount li p {
		color: #343536;
		font-size: 14px
	}

	@media screen and (max-width:600px) {
		.address {
			font-size: 12px
		}
	}
</style>
