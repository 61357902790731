<template>
	<div class="page">
		<van-nav-bar :title="$t('zaixianfuwu')" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft"
			@click-right="onClickRight" />

		<div style="font-size: 14px;">
			<p>
				<img src="https://koentestapi.xn--noexcellent-cbb.com/upload/20220301/zwI1dtK5ZP4W1x4j16pjxkZXKy81ZXAI.PNG"
					style="max-width:100%;" contenteditable="false">
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {}
		}
	}
</script>

<style>
</style>
