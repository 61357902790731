<template>
	<div class="">
		<div class="sc-13tavno-0 kLHxAV">
		  <div class="sc-13tavno-1 dgHuFF">
		    <div class="sc-13tavno-5 gjLdRx">
		     <!-- <svg width="20" height="20" viewBox="0 0 492.004 492.004">
		        <g>
		          <path fill="#354660" d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
		          C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
		          c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
		          l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z">
		          </path>
		        </g>
		      </svg> -->
		    </div>
		    <div class="sc-13tavno-2 IbIVO">{{$t('huodong')}}</div>
		    <div class="sc-13tavno-3 gtHUhW">
		      <div class="sc-13tavno-4 eMGrdg"></div>
		    </div>
		  </div>
		</div>
		
		
		
		<div class="sc-1sdnlwh-0 hMjONU">
		  <div class="sc-1sdnlwh-1" 
			:class="categoryIndex == index ? 'gGGZsi' : 'jTSGwu' " 
			v-for="(item, index) in categoryList"
			:key="index"
			@click="onCategoryClick(index)"
			>{{item.title}}</div>
		</div>
		
		<div class="sc-12k1aj7-0 hPQRyQ" v-if="list.length > 0">
			
		  <div class="sc-ub4r44-0 cENYSJ"  
			@click="onDetails(item)"
			v-for="(item, index) in list"
			:key="index"
			>
		    <div class="sc-ub4r44-1 JHfaa">
		      <div class="sc-ub4r44-2 cBgcsR">
		        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAABHNCSVQICAgIfAhkiAAAAl1JREFUKFNtkk1IFVEUx//nzsd7T8fnJypY6SOKIFwkUbSojUFgupWK2vaMXEW0iBbRokCofbXIIigiyox2RUlB9AFRKUHaqgLJzKdv3sybeXPv6YxFbTzM4cKd+c3/nPM/NDRZKjA7VwBsB6kmmxiKCJoZhoGaNogN4McEA/4QJnxu5rB3jwYng8fGcH+9o5C1GY5ASuBNzQrTP1OIUU0YKzVgJQYqNY5s42ylgYlKKeeoxrwN1LsE4dGSIZzZ6eLGpxjPviao6hRiLInqcmSkCowKGHBzltDoKmxuIngOsK1doa/dwnefcedzvAq+ntdYigSWlH+M0OCDgFsFzIva2O4MspZ0u0acfB7i3QLjV9VAniINTYpiBsg7hNacwkivg856+oem/Y29jfB+0YgaBGREJinSflFsdgFPMmcrDHRb2NstDf+N2ZLG+TcRFkODUqQEloExH6V9931ulDI9UcxYjBN9WbRI6VPfNHZ0KnR5CsOPAixLYyWZanoKW6T+uz6nanU2wbUIhQaS6TEqkjXxsZAnvPohdshQfLGkLPdSfZF23fY5taFOPJTBwlYCGOmRrI+xNh1Rwu2BfFlJUg9p9TRMI9R7s8x/1MR8URQktG11emZu8fK6QluHbMCUgBtCAdKsaoaGOkYbr/vsyqY4YoOrVNVR+uBLaniIYRL3gC23wh4d6xdBgq5IbiLZJFLqOK0fL5dlCT1XUZRz+ND0rDeBsyTb+T+6r84XAnhPZBEKiTGwFB2gwni4p6b1qYxjXfpyJPsUJPJrRM81v3Ohiovyeq4t4134Da/wKrPA4vyeAAAAAElFTkSuQmCC">
		        <label class="sc-ub4r44-3 lbrRNJ">{{item.title}}</label></div>
		      <div class="sc-ub4r44-4 cQhlSU">{{item.start_time}} - {{item.end_time}}</div></div>
		    <div class="sc-ub4r44-5 kofGAn">
		      <img alt="" :src="baseUrl + item.images" class="sc-ub4r44-6 eLdiml">
			</div>
		  </div>
		
		</div>
		<div class="sc-12k1aj7-1 iYpoNL" v-else>☹ {{$t('zanwushuju')}}</div>
	
	
	
		<div v-if="show" class="sc-13gc0dv-0 sc-13gc0dv-1 sc-1dps4kk-0 hbtMno" :class="isPopShow ? 'gHXQKh' : 'bLzdGe' ">
		  <div class="sc-13tavno-0 kLHxAV">
		    <div class="sc-13tavno-1 dgHuFF">
		      <div class="sc-13tavno-5 gjLdRx" @click="onPopShow">
		        <svg width="20" height="20" viewBox="0 0 492.004 492.004">
		          <g>
		            <path fill="#354660" d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
		            C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
		            c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
		            l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z">
		            </path>
		          </g>
		        </svg>
		      </div>
		      <div class="sc-13tavno-2 IbIVO">{{infoData.title}}</div>
		      <div class="sc-13tavno-3 gtHUhW">
		        <div class="sc-13tavno-4 eMGrdg"></div>
		      </div>
		    </div>
		  </div>
		  <div class="sc-1dps4kk-1 bKfAqf">
			<div class="sc-ub4r44-0 cENYSJ">
			  <div class="sc-ub4r44-1 JHfaa">
			    <div class="sc-ub4r44-2 cBgcsR">
			      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAABHNCSVQICAgIfAhkiAAAAl1JREFUKFNtkk1IFVEUx//nzsd7T8fnJypY6SOKIFwkUbSojUFgupWK2vaMXEW0iBbRokCofbXIIigiyox2RUlB9AFRKUHaqgLJzKdv3sybeXPv6YxFbTzM4cKd+c3/nPM/NDRZKjA7VwBsB6kmmxiKCJoZhoGaNogN4McEA/4QJnxu5rB3jwYng8fGcH+9o5C1GY5ASuBNzQrTP1OIUU0YKzVgJQYqNY5s42ylgYlKKeeoxrwN1LsE4dGSIZzZ6eLGpxjPviao6hRiLInqcmSkCowKGHBzltDoKmxuIngOsK1doa/dwnefcedzvAq+ntdYigSWlH+M0OCDgFsFzIva2O4MspZ0u0acfB7i3QLjV9VAniINTYpiBsg7hNacwkivg856+oem/Y29jfB+0YgaBGREJinSflFsdgFPMmcrDHRb2NstDf+N2ZLG+TcRFkODUqQEloExH6V9931ulDI9UcxYjBN9WbRI6VPfNHZ0KnR5CsOPAixLYyWZanoKW6T+uz6nanU2wbUIhQaS6TEqkjXxsZAnvPohdshQfLGkLPdSfZF23fY5taFOPJTBwlYCGOmRrI+xNh1Rwu2BfFlJUg9p9TRMI9R7s8x/1MR8URQktG11emZu8fK6QluHbMCUgBtCAdKsaoaGOkYbr/vsyqY4YoOrVNVR+uBLaniIYRL3gC23wh4d6xdBgq5IbiLZJFLqOK0fL5dlCT1XUZRz+ND0rDeBsyTb+T+6r84XAnhPZBEKiTGwFB2gwni4p6b1qYxjXfpyJPsUJPJrRM81v3Ohiovyeq4t4134Da/wKrPA4vyeAAAAAElFTkSuQmCC">
			      <label class="sc-ub4r44-3 lbrRNJ">{{infoData.title}}</label></div>
			    <div class="sc-ub4r44-4 cQhlSU">{{infoData.start_time}} - {{infoData.end_time}}</div></div>
			  <div class="sc-ub4r44-5 kofGAn">
			    <img alt="" :src="baseUrl + infoData.images" class="sc-ub4r44-6 eLdiml">
						</div>
			</div>
			  
		    <div class="sc-1dps4kk-2 hfpinG">{{$t('huodongneirong')}}</div>
		    <div class="sc-1dps4kk-3 igHgmM" v-html="infoData.content"></div>
		  </div>
		</div>
	
	
	
	
	
	</div>
</template>

<script>
	import {
		apiGetActivityCategory,
		apiGetActivityList,
		apiGetActivityInfo
	} from '@/request/api';
	export default {
		data() {
			return {
				show: false,
				isPopShow: false,
				
				categoryIndex: 0,
				categoryList:[],
				
				
				list: [],
				infoData: {},
				showLoading: null,
				baseUrl: this.$baseUrl
			}
		},
		
		created() {
			this.showLoading = this.$toast.loading({
			  message: this.$t('loadingText'),
			  forbidClick: true,
			  loadingType: 'spinner',
			  duration: 0
			});
			this.getActivityCategory()
			this.getActivityList()
		},
		
		methods: {
			getActivityCategory() {
				apiGetActivityCategory().then(res => {
					this.categoryList = res.data;
				})
			},
			getActivityList() {
				
				var pid = 0;
				
				if (this.categoryList.length > 0) {
					pid = this.categoryList[this.categoryIndex].id;
				}
				apiGetActivityList({
					pid: pid
				}).then(res => {
					this.showLoading.close();
					this.list = res.data;
				})
			},
			onCategoryClick(index) {
				this.list = [];
				this.categoryIndex = index;
				
				this.showLoading = this.$toast.loading({
				  message: this.$t('loadingText'),
				  forbidClick: true,
				  loadingType: 'spinner',
				  duration: 0
				});
				
				this.getActivityList()
			},
			onDetails(item) {
				this.show = true;
				this.isPopShow = true;
				this.infoData = item;
			},
			onPopShow() {
				this.show = true;
				this.isPopShow = !this.isPopShow;
			}
		}
	}
</script>

<style scoped>
	.kLHxAV {
	    background: rgb(255, 255, 255);
	    color: rgb(53, 70, 96);
	}
	.iYpoNL {
	    display: flex;
	    flex: 1 1 0%;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    justify-content: center;
	    color: rgb(53, 70, 96);
	    padding: 23px;
	}
	.dgHuFF {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: justify;
	    justify-content: space-between;
	    font-size: 16px;
	    height: 100%;
	    padding: 20px 16px 16px;
	}
	.gjLdRx {
	    flex: 1 1 0%;
	    font-size: 14px;
	    color: rgb(53, 70, 96);
	}
	.IbIVO {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    justify-content: center;
	}
	.gtHUhW {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-box-pack: end;
	    justify-content: flex-end;
	    flex: 1 1 0%;
	    color: rgb(53, 70, 96);
	    font-size: 13px;
	    text-align: right;
	}
	.eMGrdg {
	    padding-right: 5px;
	}
	.hMjONU {
	    display: flex;
	    overflow: auto;
	    font-size: 13px;
	    padding-top: 10px;
	    width: 100%;
	}
	.gGGZsi {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    flex-direction: column;
	    flex: 0 0 auto;
	    position: relative;
	    /* margin: 0px auto; */
	    color: rgb(53, 70, 96);
	    padding: 5px 10px 0px;
	}
	.gGGZsi::after {
	    content: "";
	    height: 4px;
	    margin-top: 5px;
	    width: 80%;
	    background-color: #ffc100;
	    border-radius: 2px;
	}
	.jTSGwu {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    flex-direction: column;
	    flex: 0 0 auto;
	    position: relative;
	    margin: 0px auto;
	    color: rgba(53, 70, 96, 0.6);
	    padding: 5px 10px 0px;
	}
	.jTSGwu::after {
	    content: "";
	    height: 4px;
	    margin-top: 5px;
	    width: 80%;
	    background-color: transparent;
	    border-radius: 2px;
	}
	
	.hPQRyQ {
	    flex: 1 1 0%;
	    overflow-y: scroll;
	    padding: 12px 18px 0px;
	}
	.cENYSJ {
	    margin-bottom: 10px;
	}
	.JHfaa {
	    display: flex;
	    -webkit-box-pack: justify;
	    justify-content: space-between;
	    font-size: 11px;
	}
	.cBgcsR {
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    flex: 1 1 0%;
	    -webkit-box-pack: start;
	    justify-content: flex-start;
	}
	.lbrRNJ {
	    padding-left: 5px;
	}
	.cQhlSU {
	    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAAASCAYAAADCBOXgAAAABHNCSVQICAgIfAhkiAAAAbxJREFUaEPtmkFOwkAUht+beIC6EV3RbuwWTyBHqCcQT4DeAE6AngA9Ad7AeIPuFDaDK8SNbCGR8U0jaiIRI+10lH8SVqQzf7/3ZTJ5HSaPxp0ehYrokJgTQxQwUSjx7A/j/xJIpdYTxZTSXF3tRzvpT15V3Ch/9PXoWGQ9lSS18tMgQckEJuLCeVyttL/LUaq4fT1OiE0Hu2rJqvi5/JAMn8VR5XpZvFLE1fo5mKlZh4xp+MkMqbwhwNxatvs6F3egn2qG510cC7xRw/8gzJci78nnoE7FfZP2RgIE/tNCQq8IGNOOo73WIpMzcaVjUFfMPUjrlQ5/K4zho8WZ14m4Az1qGGZ7PMAAgXUIDONwN7ITFC4upF2nTnj2C4G3I0Oh4vYfxl10DiBfzgQmsutuFyYupM25XJjunQAbdZC7uLZHO+VpTyaugzUIFEGAmS5yFTf7sMBT2+7Cp9siKoY5MwJyt+E2N3HxYQFWOSSQ5iLuvX5syvbdQo/WYek2fKm1xM2uITLbSzLJhnPE6zsm8Ctxs2OBMk20uhxXC8t9dBZWsbC76hZR9YU4YMWJyGq7BeGq5/A/CBRJ4BVxQ31pRffolwAAAABJRU5ErkJggg==);
	    background-repeat: no-repeat;
	    background-size: 120%;
	    border-top-right-radius: 12px;
	    color: rgb(53, 70, 96);
	    padding: 5px 10px;
	    text-align: end;
	    width: 200px;
	}
	.kofGAn {
	    background-color: rgb(217, 224, 232);
	    border-radius: 12px 0px 12px 12px;
	    border: 3px solid rgb(217, 224, 232);
	}
	.eLdiml {
	    display: block;
	    border-radius: 10px;
	    max-height: 100%;
	    max-width: 100%;
	}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	/* 弹窗样式 */
	
	.hbtMno {
	    position: fixed;
	    top: 0px;
	    left: 0px;
	    bottom: 0px;
	    opacity: 1;
	    overflow: hidden scroll;
	    width: 100%;
	    z-index: 100;
	    animation-duration: 0.3s;
	    animation-timing-function: ease-in-out;
	    animation-fill-mode: both;
	}
	.bLzdGe {
	    animation-name: lndToK;
	}
	.gHXQKh {
	    animation-name: fqEUgu;
	}
	@keyframes lndToK {
		0% {
			transform: translateX(0px);
		}
		100% {
			transform: translateX(100%);
		}
	}
	@keyframes fqEUgu {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(0px);
		}
	}
	
	.bKfAqf {
	    overflow-y: scroll;
	    height: calc(100% - 54px);
	    padding: 12px 18px 0px;
	    background-color: rgb(255, 255, 255);
	}
	
	.hfpinG {
	    text-align: center;
	    font-size: 12px;
	    margin-top: -5px;
	    color: rgb(53, 70, 96);
	}
	
	.igHgmM {
	    margin-bottom: 0px;
	}
	
</style>