<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('shourujilu')" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft"
			@click-right="onClickRight" />
	
	  <div class="inc-panel">
	    <div class="inc-sidebar">
			
			<van-sidebar v-model="activeKey" @change="onChange">
			  <van-sidebar-item :title="item.name" v-for="(item,index) in sourceList" />
			</van-sidebar>
	    </div>
	    <div class="lists">
			
			
			<van-pull-refresh
				:pulling-text="$t('pullingText')"
				:loosing-text="$t('loosingText')"
				:loading-text="$t('loadingText')"
				v-model="refreshing" @refresh="onRefresh">
				<van-list
				  :loading-text="$t('loadingText')"
				  v-model="loading"
				  :finished="finished"
				  :finished-text="$t('meiyougengduo')"
				  @load="onLoad"
				>
				  <div role="feed" class="van-list" style="padding-right: 5px;">
				    <div class="inc-card"  :key="index" v-for="(item,index) in fundingRecords">
				      <div class="flex_bd">
				        <div class="wt-items">
				          <div class="label">{{$t('jine')}}</div>
				          <div class="value text-green">{{item.price}}</div>
				        </div>
				        <div class="wt-items">
				          <div class="label">{{$t('shijian')}}</div>
				          <div class="time">{{item.create_time}}</div>
				        </div>
				      </div>
				      <div class="inc-info" v-if="item.source == 5">{{$t('levexiajidingdan', {n: item.agent_level})}}</div>
					  <div class="inc-info" v-else>{{item.txt}}</div>
				    </div>
				  	        
				  </div>
				</van-list>
			</van-pull-refresh>
	   
	    </div>
	  </div>
	</div>
</template>

<script>
	import {
		apiUserInfo,apiGetfundingRecords
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				sourceList: [
					{
						name: this.$t('kuanjishouyi'),
						id: 4
					},
					{
						name: this.$t('dailifanyong'),
						id: 5
					}
				],
				fundingRecords: [],
				loading: false,
				finished: false,
				refreshing: false,
				page: 0,
				activeKey: 0,
				source: 0
			}
		},
		created() {
			this.source = this.sourceList[this.activeKey].id;
			// this.getfundingRecords()
		},
		methods: {
			onChange(index) {
				this.source = this.sourceList[index].id;
				this.onRefresh();
			},
			onLoad() {
				console.log(1);
				this.page++;
				this.getfundingRecords()
			},
			onRefresh() {
			  // 清空列表数据
			  this.finished = false;
		
			  // 重新加载数据
			  // 将 loading 设置为 true，表示处于加载状态
			  this.loading = true;
			  // this.refreshing = true;
			  this.fundingRecords = [];
			  this.page = 1;
			  this.getfundingRecords();
			},
			getfundingRecords() {
				apiGetfundingRecords({page:this.page, source: this.source}).then(res => {
					// this.fundingRecords = res.data.data;
					if (this.fundingRecords.length >= res.data.count) {
						this.finished = true;
					}
					this.fundingRecords = [...this.fundingRecords,...res.data.data]
					this.loading = false;
					this.refreshing = false
					
					
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {}
		}
	}
</script>

<style scoped>
	.inc-sidebar .van-sidebar-item__text {
		width:100%;
		word-break:break-word
	}
	.wt-items:last-child {
		margin-bottom:0!important
	}
	.inc-panel .pane {
		min-height:100vh
	}
	.inc-sidebar {
		background:#f5fbff;
		box-shadow:0 .12077rem .43478rem 0 rgba(0,0,0,.2)
	}
	.inc-sidebar .van-sidebar-item {
		background-color:#f5fbff
	}
	.van-sidebar-item--select,.van-sidebar-item--select:active {
		background-color:transprent
	}
	
	.inc-card {
		background:#f5fbff;
		box-shadow:0 .12077rem .12077rem 0 rgba(0,0,0,.2)
	}
	.wt-items .label {
		color:#333
	}
	.wt-items .value {
		color:#58ab6b
	}
	.van-list {
		padding-top:.32rem
	}
	.van-sidebar-item--select .van-sidebar-item__text {
	    background-color: #ffc100;
	    color: #000;
		width: 100%;
	}
	
	.van-sidebar-item__text{
		background-color:#f3f3f3;
		color:#333;
	}
</style>