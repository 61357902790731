module.exports = {
  zhuye: 'หน้าแรก',
  tuandui: 'ทีมงาน',
  zizhan:'บัญชีของฉัน',
  lang_Zh: 'ชาวจีน',
  lang_En: 'ภาษาอังกฤษ',
  lang_Lt: 'ภาษาอิตาลี',
  nihao: 'สวัสดี',
  fuzhi: 'คัดลอก',
  loadingText: 'กำลังโหลด..',
  loosingText: 'ปล่อยเพื่อรีเฟรช...',
  pullingText: 'ดึงลงเพื่อรีเฟรช....',
  shijijine: 'จำนวนเงินที่ชำระจริง',
  jiaoyihaxi: 'แฮชธุรกรรม',
  chengongdingou: 'สั่งซื้อสำเร็จ',
  jinrigerenshouru: 'รายได้ส่วนบุคคลวันนี้',
  touzizqdybsr: 'ลงทุนและรับรายได้แรกของคุณ',
  diyibitouzi: 'เริ่มการลงทุนครั้งแรกของคุณ',
  jiqiren: 'หุ่นยนต์',
  chakanwodejiqirenck: 'ดูที่เก็บหุ่นยนต์ของฉัน',
  wodejiqirenshuliang: 'จำนวนหุ่นยนต์ของฉัน',
  goumaihetong: 'สัญญาซื้อ',
  jiage: 'ราคา',
  youxiaoqi: 'ระยะเวลาที่ถูกต้อง',
  yujirishouru: 'รายได้รวมโดยประมาณ',
  jintian: 'วันนี้',
  benxingqi: 'สัปดาห์นี้',
  huoqushijian: 'เวลาเปิดใช้งาน',
  zuihoujihuoshijian: 'เวลาสิ้นสุด',
  jingguoshijian: 'เวลาที่ผ่านไป',
  weiyidaima: 'รหัสที่ไม่ซ้ำ',
  tian: 'วัน',
  shi: 'ชั่วโมง',
  fen: 'นาที',
  ren: 'คน',
  qidongzijin: 'ทุนเริ่มต้น',
  fuwu:'ให้บริการ',
  fenxiangyaoqing: 'แบ่งปันคำเชิญ',
  chongzhi: 'เติมเงิน',
  tixian: 'ถอนเงินสด',
  zaixianfuwu: 'บริการออนไลน์',
  bangzhuzhinan: 'คู่มือช่วยเหลือ',
  guanyuwomen: 'เกี่ยวกับเรา',
  hezuohuoban: 'พันธมิตร',
  dangqianyue: 'ยอดคงเหลือปัจจุบัน',
  zhifu: 'จ่าย',
  wenxitishi: 'เคล็ดลับดีๆ',
  hangqing: 'ตลาดลงทุน',
  muqiangongyountaijiqirenxiadan:'ขณะนี้มีหุ่นยนต์ {n} ตัวที่ทำการสั่งซื้อโดยอัตโนมัติ',
  dangqianjiqirendengji: 'ระดับหุ่นยนต์ปัจจุบัน',
  zhuangtai: 'สถานะ',
  zhengchang: 'ปกติ',
  zanwushuju: 'ยังไม่มีข้อมูล',
  qujiaoyisuo: 'ไปที่การแลกเปลี่ยน',
  wodetuandui: 'ทีมของฉัน',
  tuanduichongzhi: 'เติมเงินทีม',
  tuijianguize: 'กฎเกณฑ์ที่แนะนำ',
  huiyuanjianjie: 'ประวัติสมาชิก',
  wodezichan: 'รายได้ของฉัน',
  zongyunyingzijin: 'เงินทุนหมุนเวียนทั้งหมด',
  wodeqianbaoyue: 'ยอดคงเหลือในกระเป๋าของฉัน',
  gengduo: 'เพิ่มเติม',
  tuanduijianglu: 'รางวัลของทีม',
  kuanjishouyi: 'รายได้หุ่นยนต์',
  dailifanyong: 'ค่านายหน้าเคาน์เตอร์ตัวแทน',
  quanbu: 'ทั้งหมด',
  levexiajidingdan: '{n} คำสั่งระดับรอง',
  yaoqingma: 'รหัสคำเชิญ',
  gerenshouruleiji: 'รายได้ส่วนบุคคลสะสม',
  jinrishouruleiji: 'รายได้ตัวแทนวันนี้',
  dailileijishouru: 'รายได้รวมของตัวแทน',
  jiqirenhuikou: 'ส่วนลดหุ่นยนต์',
  wodeGcash: 'บัตรธนาคาร',
  jiaoyijilu: 'ประวัติการทำธุรกรรม',
  shourujilu: 'บันทึกรายได้',
  feiyongjilu: 'บันทึกค่าใช้จ่าย',
  tikuanjilu: 'ประวัติการถอนเงิน',
  chunkuanjilu: 'บันทึกการฝากเงิน',
  chunkuanqudao: 'ช่องทางการฝากเงิน',
  fankui: 'ข้อเสนอแนะ',
  chunkuanshuoming: 'คำแนะนำในการฝากเงิน',
  genggaidenglumima: 'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
  dengchu: 'ออกจากระบบ',
  fuzhichengg: 'คัดลอกเรียบร้อยแล้ว',
  fuzhishibai: 'การคัดลอกล้มเหลว',
  dailifanli: 'ส่วนลดเอเจนซี่',
  meiyougengduo: 'ไม่มีอีกแล้ว',
  jine: 'จำนวน',
  shouxufei: 'ค่าเช่าหุ่นยนต์',
  tixianqudao: 'ช่องทางการถอนเงิน',
  shenqingshijian: 'เวลาสมัคร',
  qingshurudizhi: 'กรุณากรอกที่อยู่',
  zhichu: 'ค่าใช้จ่าย',
  qingshurujine: 'กรุณากรอกจำนวนเงิน',
  tianjia: 'เพิ่มไปที่',
  baochou: 'ค่าตอบแทน',
  shijian: 'เวลา',
  yaoqingguize: 'กฎการเชิญชวน',
  yaoqingpengyou: 'ชวนเพื่อน',
  dailimoshi: 'โหมดพร็อกซี',
  yaoqingpengyouyiqizhuanqian: 'ชวนเพื่อนมาสร้างรายได้ด้วยกัน',
  fanlishengjiyaoqinghaoyou: 'รับส่วนลดอัพเกรดและเชิญเพื่อนมาแบ่งปันส่วนลดการทำธุรกรรมสูงสุดถึง 16% และเป็นผู้นำทีมกับเพื่อน ๆ ของคุณ!',
  tuijianlianjie: 'ลิงค์แนะนำ',
  wodejiqiren: 'หุ่นยนต์ของฉัน',
  yimaichujiqiren: 'หุ่นยนต์ขายแล้ว',
  zaigongzuo: 'ที่ทำงาน',
  yimaichu: 'ขายแล้ว',
  laiyuan: 'แหล่งที่มา',
  hetonggoumaai: 'สัญญาซื้อ',
  wodexiaoxi: 'ข้อความของฉัน',
  xiaoxixiangqing: 'รายละเอียดข้อความ',
  dengji: 'ลงทะเบียน',
  lijichuangjianzhanghu: 'สร้างบัญชีตอนนี้',
  miaokuaisukaihu: 'เปิดบัญชีอย่างรวดเร็วใน 30 วินาที',
  xuanzeyuyan: 'เลือกภาษา',
  queren: 'ยืนยัน',
  quxiao: 'ยกเลิก',
  
  bangzhuzhongxin: 'ศูนย์ช่วยเหลือ',
  chuangjianwenti: 'คำถามที่พบบ่อย',
  qianbaodizhi: 'ที่อยู่กระเป๋าเงิน',
  qingshuruqianbaodizhi:'กรุณากรอกที่อยู่กระเป๋าเงิน',
  qianbaodizhibangding: 'การผูกที่อยู่กระเป๋าเงิน',
  xuanzetikuanfangshi: 'เลือกวิธีการถอนเงิน',
  qiangleituijian20: 'ขอแนะนำ TRC20',
  chenggongzhifu: 'ชำระเงินสำเร็จ',
  tixiantishi: "เคล็ดลับการถอน: </br>\
		  1. เวลาในการทำธุรกรรมการถอนเงินจะเข้าบัญชีของคุณอยู่ที่ 5 นาทีถึง 1 ชั่วโมง</br>\
		  2. เวลาถอนเงินคือตั้งแต่ 11.00 น. ถึง 23.00 น.</br>\
		  หากคุณไม่ได้รับเงินที่ถอนออกด้วยเหตุผลใดก็ตาม</br>\
		  โปรดติดต่อฝ่ายบริการลูกค้า</br>\
		  3. จำนวนเงินถอนขั้นต่ำ 30 บาท สามารถทำรายการถอนได้ 1 ครั้งต่อวัน</br>",
  pay: {
	  t1: 'โปรดใช้กระเป๋าเงินที่รองรับ Tron เพื่อสแกนรหัส QR หรือคัดลอกและวางจำนวนเงินและที่อยู่ลงในกระเป๋าเงินของคุณเพื่อชำระเงิน',
	  p1: 'หากคุณใช้ TP wallet ในการโอน โปรดเลือกประเภทสกุลเงินเป็น USDT ขอแนะนำให้ใช้การแลกเปลี่ยนและ imtoken สำหรับการโอน',
	  p2:'หลังจากชำระเงินสำเร็จ โดยปกติจะใช้เวลาประมาณหนึ่งนาทีกว่าที่การชำระเงินจะมาถึงสำเร็จ',
	  p3: 'คลิกพื้นที่จำนวนและที่อยู่เพื่อคัดลอกโดยตรง',
	  p4: 'จำนวนเงินที่ชำระจะต้องสอดคล้องกับจำนวนการสั่งซื้อที่แสดงด้านบน ไม่เช่นนั้นระบบอาจไม่เข้าบัญชี',
  },
  inst: {
	  t: 'ใช้ USDT',
	  p1: 'เลือกวิธี USDT',
	  p2: 'กรอกยอดคงเหลือที่ต้องเติม (USDT) ในคอลัมน์ยอดคงเหลือ และคลิกปุ่ม "ยืนยัน" ด้านล่าง',
	  p3: 'หลังจากได้รับที่อยู่กระเป๋าเงิน CALA แล้ว ให้โอน USDT (TRC20) ผ่านแอปกระเป๋าเงินเข้ารหัส',
	  p4: 'หลังจากการโอนเงินเสร็จสิ้น ให้กรอกและอัปโหลดที่อยู่กระเป๋าเงินการชำระเงินและภาพหน้าจอใบสำคัญการชำระเงิน แล้วคลิก "บันทึกและส่ง"',
	  p5: '(ขอแนะนำให้ใช้ USDT เพื่อเติมเงิน ไม่มีค่าธรรมเนียมการจัดการ การเติมเงินและการถอนเงินในช่วงเวลาทำการจะเข้าบัญชีของคุณแบบเรียลไทม์)'
  },
  
  help: {
	p1: 'หุ่นยนต์ซื้อขายเชิงปริมาณหุ่นยนต์ (AI) ทำงานอย่างไร',
	p1_1: 'การซื้อผลิตภัณฑ์ในราคาต่ำและขายในราคาที่สูงกว่านั้นกลายเป็นเรื่องที่ท้าทายมากขึ้น เนื่องจากความแออัดในปัจจุบันของเครือข่ายธุรกรรม Bitcoin และค่าธรรมเนียมการโอนที่สูง ความไร้ประสิทธิภาพของตลาดจะนำไปสู่อัตรากำไรที่พุ่งสูงขึ้น <br>ตัวอย่างเช่น ยังคงมีความแตกต่างของราคาระหว่างการซื้อขาย BTC/USDT ของ Binance และ Gate หากคุณซื้อ BTC บน Gate แล้วส่งไปที่ Binance และขายมันทันที คุณจะได้รับ USDT มากขึ้นผ่านการเก็งกำไร จากนั้นคุณส่ง USDT จาก Binance ไปยัง Gate และซื้อ BTC วงจรนี้จะเกิดขึ้นซ้ำอีกครั้ง โดยได้กำไรจากส่วนต่างของราคา &nbsp;',
	p2: 'การจัดการทางการเงินที่ชาญฉลาดคืออะไร?',
	p2_1: 'การจัดการทางการเงินอัจฉริยะแสดงถึงการลงทุนในการจัดการทางการเงินผ่านหุ่นยนต์ (AI) ที่รวมเครื่องจักรซึ่งมาแทนที่หรือเสริมโปรแกรมหรือนักวางแผนทางการเงินแบบดั้งเดิมที่เรียบง่าย ในฐานะที่เป็นส่วนขยายของเทคโนโลยีการลงทุนการซื้อขายเชิงปริมาณ มันเป็นวิธีการจัดการระดับโลกที่เป็นนวัตกรรม เกิดใหม่ และยั่งยืน พร้อมด้วยวิสัยทัศน์การลงทุนที่กว้าง ลดต้นทุนการถือครองผ่านการลงทุนคงที่ระยะยาวเพื่อให้บรรลุเป้าหมายกำไร<br> ได้รับการพิสูจน์แล้วว่านำมาซึ่งโอกาสในการวางแผนทางการเงินมากขึ้น และผลกำไรมหาศาลสำหรับผู้จัดการทางการเงิน',
	p3: 'ข้อดีของหุ่นยนต์อัจฉริยะ',
	p3_1: '1，เอาชนะจุดอ่อนตามธรรมชาติของมนุษย์ ไม่มีความโลภและความกลัว มีวินัยที่เข้มแข็ง ใช้กลยุทธ์การลงทุนอย่างเคร่งครัด และไม่ได้รับผลกระทบจากการเปลี่ยนแปลงทัศนคติของนักลงทุน &nbsp;<br>2. ลักษณะที่เป็นระบบของแบบจำลอง: แบบจำลองเชิงปริมาณหลายระดับ การสังเกตหลายมุม และการประมวลผลข้อมูลขนาดใหญ่ รวมกับเทคโนโลยีการประมวลผลข้อมูลขนาดใหญ่ เพื่อดึงดูดโอกาสในการลงทุนมากขึ้น <br>3. ทันเวลา รวดเร็วและแม่นยำ: ติดตามการเปลี่ยนแปลงของตลาดได้ทันท่วงทีและรวดเร็ว ค้นพบแบบจำลองทางสถิติใหม่ ๆ อย่างต่อเนื่องที่สามารถให้ผลตอบแทนที่มากเกินไป มองหาโอกาสในการซื้อขายใหม่ ๆ และรับประกันคำสั่งซื้อที่แม่นยำในเวลาเดียวกัน ซึ่งไม่มีใครเทียบได้กับการซื้อขายแบบอัตนัย.',
  },
  tips: {
	  p1: 'หุ่นยนต์จะทำงานตามเวลา โปรดเลือกหุ่นยนต์ที่ทำงานได้ประสิทธิภาพสูงสุด',
	  p2: 'ยิ่งระดับเวลาการทำงานของหุ่นยนต์สูงเท่าไหร่ประสิทธิภาพการทำงานก็สูงเท่านั้นและจะได้กำไรมากขึ้น',
  },
  user: {
	  haimeizhanghu: 'ยังไม่มีบัญชี',
	  yonghuming: 'ชื่อผู้ใช้',
	  mima: 'รหัสผ่าน',
	  ninyoumeiyzhanghu: 'คุณมีบัญชีไหม',
	  qingshurnindeyonghuming: 'กรุณากรอกชื่อผู้ใช้ของคุณ',
	  querenmima: 'ยืนยันรหัสผ่าน',
	  qingshumima: 'กรุณากรอกรหัสผ่าน',
	  qingshurumima: 'กรุณากรอกรหัสผ่านยืนยัน',
	  qingshuruyaoqingma: 'กรุณากรอกรหัสเชิญ',
	  yuebuzu: 'ยอดคงเหลือไม่เพียงพอ',
	  huiyuandengji: 'สมาชิกระดับที่ {n} ',
	  levjidaili: 'ตัวแทนระดับที่ {n}',
	  denglu: 'เข้าสู่ระบบ',
	  xiugaidenglumima: 'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
	  yuanshimima: 'รหัสผ่านเดิม',
	  qingshuruyuanima: 'กรุณากรอกรหัสผ่านเดิม',
	  xinmima: 'รหัสผ่านใหม่',
	  qinghuruxinmima: 'กรุณากรอกรหัสผ่านใหม่',
	  liangcishurumimabuyizhi: 'กรุณากรอกรหัสผ่านใหม่',
	  
	  denglumima: 'รหัสผ่านเข้าสู่ระบบ',
	  qingshurudenglumima: 'กรุณากรอกรหัสผ่านเข้าสู่ระบบของคุณ'
  },
  about: {
	  p1: 'Koen Ai เป็นนายหน้าซื้อขายหลักทรัพย์ทางอินเทอร์เน็ตและช่องทางสกุลเงินดิจิตอลดิจิทัลที่จดทะเบียนใน Nasdaq ในสหรัฐอเมริกา! มีใบอนุญาตนายหน้าซื้อขายหลักทรัพย์ของสหรัฐอเมริกาและใบอนุญาตประกอบธุรกิจสกุลเงินดิจิทัล และได้รับการลงทุนเชิงกลยุทธ์จาก Interactive Brokers ในสหรัฐอเมริกา Koen ai มอบธุรกรรมสกุลเงินดิจิทัลให้กับผู้ใช้ และมอบประสบการณ์การลงทุนระดับโลกระดับเฟิร์สคลาสให้กับนักลงทุน!',
	  p2:'การจัดการทางการเงิน ทางเลือกหรือเสริมจากโปรแกรมทางการเงินหรือนักวางแผนแบบดั้งเดิมที่เรียบง่ายผ่านการลงทุนปัญญาประดิษฐ์ (AI) ที่รวมเครื่องจักร ในฐานะที่เป็นส่วนขยายของเทคโนโลยีการลงทุนการซื้อขายเชิงปริมาณ มันเป็นวิธีการจัดการระดับโลกที่เป็นนวัตกรรม เกิดใหม่ และยั่งยืน พร้อมด้วยวิสัยทัศน์การลงทุนที่กว้าง ลดต้นทุนการถือครองผ่านการลงทุนคงที่ระยะยาวเพื่อให้บรรลุเป้าหมายกำไร<br> ได้รับการพิสูจน์แล้วว่านำมาซึ่งโอกาสในการวางแผนทางการเงินมากขึ้น และผลกำไรมหาศาลสำหรับผู้จัดการทางการเงิน',
	  p3:'Koen BOT ได้รับใบอนุญาต US MSB',
	  p4: 'การจำลองเชิงปริมาณหลายระดับ การสังเกตหลายมุม รวมกับเทคโนโลยีการประมวลผลและการวิเคราะห์ข้อมูลขนาดใหญ่ เพื่อดึงดูดโอกาสในการลงทุนมากขึ้น',
	  p5: 'หุ่นยนต์อัจฉริยะ Koen Ai สามารถทำงานได้อย่างต่อเนื่องตลอด 24 ชั่วโมง (ไม่จำเป็นต้องตั้งค่าด้วยตนเอง ประสิทธิภาพสูง) ไม่จำเป็นต้องดูตลาด การลงทุนที่ชาญฉลาดนั้นง่ายกว่าและมีเหตุผลมากกว่า (ใช้ได้กับคนส่วนใหญ่ แม้ผู้ที่ไม่มีประสบการณ์การลงทุนก็สามารถใช้งานได้อย่างมั่นใจ）'
  },
  proxyRule: {
	  p1: 'ส่วนลดค่าคอมมิชชันของทีม',
	  p2: 'หากคุณเชิญเพื่อนหนึ่งคนเข้าร่วม คุณจะได้รับส่วนลด 8% ของกำไรของสมาชิกที่ชวนมาในระดับแรก เมื่อสมาชิกระดับแรกของคุณเชิญเพื่อน คุณจะได้รับ 5% ของกำไร ของสมาชิกระดับที่สอง เมื่อสมาชิกระดับที่สอง คุณจะได้รับเงิน 3% ของกำไรของสมาชิกระดับที่สาม รวม 16% จากทั้ง 3 ระดับ',
	  p3: 'นี่เป็นผลประโยชน์ที่เพิ่มเข้ามาอย่างมากจาก Koen Ai เพื่อพัฒนาตลาด ยิ่งสมาชิกแต่ละคนเข้าร่วมตัวแทนเร็วเท่าไร รายได้ก็จะเติบโตอย่างรวดเร็วเมื่อทีมเติบโตขึ้น ตอนนี้เป็นเวลาที่ดีมากในการเข้าร่วมตัวแทน ยิ่งไปกว่านั้น ส่วนลดตัวแทนของคุณจะถูกโอนไปยังผลกำไรของผู้ใต้บังคับบัญชาของคุณแบบเรียลไทม์ ผลกำไรของตัวแทนคุณสามารถถอนออกหรือลงทุนได้ตลอดเวลา ฉันเชื่อว่าตัวแทนที่ดีทุกคนในทีมจะมีรายได้ที่สูงมากในอีกไม่กี่ปีข้างหน้าและจะมีรายได้ โอกาสในการทำเช่นนั้น คุณทำเงินล้านแรกในช่วงเวลาที่ทำกำไรได้มากที่สุดของสกุลเงินเสมือน',
  },
  guanbi: 'ปิด',
  gonggao: 'ประกาศ',
  genggaizijinmima: 'เปลี่ยนรหัสผ่านการถอนเงิน',
  yinhangmingcheng: 'บัญชีธนาคาร',
  zhanghumingcheng: 'ชื่อบัญชี',
  kahao: "หมายเลขบัญชี",
  lianxidianhua: 'เบอร์ติดต่อ',
  kaisheyinhangzhanghu: 'บัญชีธนาคาร',
  shangchuanfukuanjietu: 'อัพโหลดภาพหน้าจอการชำระเงิน',
  qingxuanzeyinhangka: 'โปรดเลือกธนาคาร',
  xuanzeyanhang: 'เลือกธนาคาร',
  tijiao: 'ส่ง',
  qingshuruyinhangmingcheng: 'กรุณากรอกบัญชีธนาคาร',
  qingshuruyinhangkahao: 'กรุณากรอกหมายเลขบัญชี',
  qingshurulianxidianhua: 'กรุณากรอกหมายเลขติดต่อ',
  tixianmima: 'รหัสผ่านการถอนเงิน',
  qingshurutixianmima: 'กรุณากรอกรหัสผ่านการถอนเงิน',
  huodong: 'กิจกรรม',
  zijinmima: 'กรอกรหัสผ่านใหม่',
  qingshurujinjinmima: 'ป้อนรหัสผ่านการถอนเงินใหม่ของคุณ',
  querenzijinmima: 'ยืนยันรหัสผ่าน',
  qingshuruquerenzijinimma: 'ยืนยันรหัสการถอนเงินใหม่อีกครั้ง',
  huodongneirong: 'เนื้อหากิจกรรม',
  qingshuruyinhangmingchengexuanze: 'กรุณากรอกชื่อธนาคาร',
  xiayiye: 'หน้าถัดไป'
}