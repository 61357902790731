<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('qianbaodizhibangding')" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft"
			@click-right="onClickRight" />
	
	  <div class="wrapper">
	    <div class="namsa">USDT</div>
	    <div class="ui-tabs van-tabs van-tabs--line">
	      <div class="van-tabs__wrap">
	        <div role="tablist" class="van-tabs__nav van-tabs__nav--line">
	          <div role="tab" aria-selected="true" class="van-tab van-tab--active">
	            <span class="van-tab__text van-tab__text--ellipsis">
	              <div class="tm-tab-title">TRC20</div>
	            </span>
	          </div>
	          <div class="van-tabs__line" style="width: 177.5px; transform: translateX(177.5px) translateX(-50%);"></div>
	        </div>
	      </div>
	      <div class="van-tabs__content">
	        <div role="tabpanel" class="van-tab__pane" style=""></div>
	      </div>
	    </div>
	    <div class="mabox mabox-form" style="text-align: left;">
	      <form class="bs-form van-form">
	        <div class="van-cell van-field">
	          <div class="van-cell__title van-field__label">
	            <span class="text-green">{{$t('qianbaodizhi')}}</span>
	          </div>
	          <div class="van-cell__value van-field__value">
	            <div class="van-field__body">
	              <input type="text" v-model="withdraw_usdt_account" :placeholder="$t('qingshuruqianbaodizhi')" class="van-field__control"></div>
	          </div>
	        </div>
	        <div style="word-break: break-all; padding: 10px 20px; text-align: left; line-height: 20px; font-size: 12px;"></div>
	      </form>
	    </div>
	    <div class="tips" style="padding-top: 10px;" v-html="$t('tixiantishi', {n: configData.MinWithdrawalAmount})">
	      
	    </div>
	    <div class="mabox-space">
		<van-button class="queren"  @click="onSubmit">{{$t('queren')}}</van-button>
	   
	    </div>
	  </div>
	</div>
</template>

<script>
	import {
		apiUserInfo,
		apiUpdateInfo,
		apiConfigData
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				userInfo:{},
				withdraw_usdt_account: '',
				configData: {}
			}
		},
		created() {
			this.getUserInfo();
			this.getConfigData()
		},
		methods: {
			getConfigData() {
				apiConfigData().then(res => {
					this.configData = res.data;
				})
			},
			getUserInfo() {
				apiUserInfo().then(res => {
					this.userInfo = res.data;
					this.withdraw_usdt_account = res.data.withdraw_usdt_account
				})
			},
			onSubmit() {
				
				if (!this.withdraw_usdt_account) {
					this.$toast(this.$t('qingshurudizhi'));
					return false;
				}
				
				apiUpdateInfo({withdraw_usdt_account:this.withdraw_usdt_account}).then(res => {
					if (res.code != 0) {
						return this.$toast(res.msg);
					}
					
					this.$toast('ok');
					
					setTimeout(()=>{
						this.$router.push('/ucenter')
					},2000)
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
			}
		}
	}
</script>

<style scoped>
	.namsa {
		color:#000;
		height:1.15942rem;
		border-radius:.24155rem;
		background:#f1f0ed;
		line-height:1.15942rem;
		padding:0 .36232rem;
		margin-bottom:.48309rem;
		font-size:.38647rem;
	}
	.tips {
		font-size:.33816rem;
		color:#7a869a;
	}
	.queren {
		color:#000;
		width:100%;
		height:.96618rem;
		border-radius:.24155rem;
		border:none;
		background-color:#ffc100
	}

</style>