module.exports = {
  zhuye: '主页',
  tuandui: '团队',
  zizhan:'资产',
  lang_Zh: '中文',
  lang_En: '英文',
  lang_Lt: '意大利文',
  nihao: '你好',
  fuzhi: '复制',
  loadingText: '加载中...',
  loosingText: '释放即可刷新...',
  pullingText: '下拉即可刷新...',
  shijijine: '实际支付金额',
  jiaoyihaxi: '交易哈希',
  chengongdingou: '成功订购',
  jinrigerenshouru: '今天的个人收入',
  touzizqdybsr: '投资，赚取第一笔收益',
  diyibitouzi: '开始第一笔投资',
  jiqiren: '机器人',
  chakanwodejiqirenck: '查看我的机器人仓库',
  wodejiqirenshuliang: '我的机器人数量',
  goumaihetong: '购买合同',
  jiage: '价格',
  youxiaoqi: '有效期',
  yujirishouru: '预计总收益',
  jintian: '今天',
  benxingqi: '本星期',
  huoqushijian: '激活时间',
  zuihoujihuoshijian: '结束时间',
  jingguoshijian: '经过时间',
  weiyidaima: '唯一代码',
  tian: '天',
  shi: '时',
  fen: '分',
  ren: '人',
  qidongzijin: '启动资金',
  fuwu:'服务',
  fenxiangyaoqing: '分享邀请',
  chongzhi: '充值',
  tixian: '提现',
  zaixianfuwu: '在线服务',
  bangzhuzhinan: '帮助指南',
  guanyuwomen: '关于我们',
  hezuohuoban: '合作伙伴',
  dangqianyue: '当前余额',
  zhifu: '支付',
  wenxitishi: '温馨提示',
  hangqing: '行情',
  muqiangongyountaijiqirenxiadan:'目前共有 {n} 台机器人自动下单',
  dangqianjiqirendengji: '当前机器人等级',
  zhuangtai: '状态',
  zhengchang: '正常',
  zanwushuju: '暂无数据',
  qujiaoyisuo: '去交易所',
  wodetuandui: '我的团队',
  tuanduichongzhi: '团队充值',
  tuijianguize: '推荐规则',
  huiyuanjianjie: '会员简介',
  wodezichan: '我的资产',
  zongyunyingzijin: '总营运资金',
  wodeqianbaoyue: '我的钱包余额',
  gengduo: '更多',
  tuanduijianglu: '团队奖励',
  kuanjishouyi: '机器人收益',
  dailifanyong: '代理反佣',
  quanbu: '全部',
  levexiajidingdan: '{n}级下级订单',
  yaoqingma: '邀请码',
  gerenshouruleiji: '个人收入积累',
  jinrishouruleiji: '今日代理收入',
  dailileijishouru: '代理累计收入',
  jiqirenhuikou: '机器人回扣',
  wodeGcash: '我的银行卡',
  jiaoyijilu: '交易记录',
  shourujilu: '收入记录',
  feiyongjilu: '费用记录',
  tikuanjilu: '提款记录',
  chunkuanjilu: '存款记录',
  chunkuanqudao: '存款渠道',
  fankui: '反馈',
  chunkuanshuoming: '存款说明',
  genggaidenglumima: '更改登录密码',
  dengchu: '登出',
  fuzhichengg: '复制成功',
  fuzhishibai: '复制失败',
  dailifanli: '代理返利',
  meiyougengduo: '没有更多了',
  jine: '金额',
  shouxufei: '手续费',
  tixianqudao: '提现渠道',
  shenqingshijian: '申请时间',
  qingshurudizhi: '请输入地址',
  zhichu: '支出',
  qingshurujine: '请输入金额',
  tianjia: '添加',
  baochou: '报酬',
  shijian: '时间',
  yaoqingguize: '邀请规则',
  yaoqingpengyou: '邀请朋友',
  dailimoshi: '代理模式',
  yaoqingpengyouyiqizhuanqian: '邀请朋友一起赚钱',
  fanlishengjiyaoqinghaoyou: '返利升级，邀请好友分享高达16%的交易返利，与好友一起做老板！',
  tuijianlianjie: '推荐链接',
  wodejiqiren: '我的机器人',
  yimaichujiqiren: '已卖出机器人',
  zaigongzuo: '在工作',
  yimaichu: '已卖出',
  laiyuan: '来源',
  hetonggoumaai: '合同购买',
  wodexiaoxi: '我的消息',
  xiaoxixiangqing: '消息详情',
  dengji: '登记',
  lijichuangjianzhanghu: '立即创建帐户',
  miaokuaisukaihu: '30秒快速开户',
  xuanzeyuyan: '选择语言',
  queren: '确认',
  quxiao: '取消',
  
  bangzhuzhongxin: '帮助中心',
  chuangjianwenti: '常见问题',
  qianbaodizhi: '钱包地址',
  qingshuruqianbaodizhi:'请输入钱包地址',
  qianbaodizhibangding: '钱包地址绑定',
  xuanzetikuanfangshi: '选择提款方式',
  qiangleituijian20: '强烈推荐TRC20',
  chenggongzhifu: '成功支付',
  tixiantishi: '提现提示： </br>\
		  1、提款交易需要 5 分钟到 1 小时才能到达您的帐户</br>\
		  2、如果您因任何原因没有收到提款，提款时间为上午 11:00 至晚上 11:00。请联系客服。</br>\
		  3、最低提款金额为 30 泰铢，每天可提款 1 次。 </br>',
  pay: {
	  t1: '请使用支持波场网络（Tron）的钱包扫描二维码或将金额和地址复制粘贴到你的钱包进行支付',
	  p1: '如果使用TP钱包转账，请选择货币种类为USDT。推荐使用交易所、imtoken转账。',
	  p2: '支付成功后一般在一分钟左右可成功到账。',
	  p3: '点击金额和地址区域可直接复制。',
	  p4: '支付金额需要与上方显示的订单金额一致，否则系统可能不到账。'
  },
  inst: {
	  t: '使用USDT',
	  p1: '选择USDT方式',
	  p2: '在余额栏中填写需要充值的余额（USDT），点击下方“确认”按钮',
	  p3: '获取CALA钱包地址后，转账USDT (TRC20) 通过加密钱包app',
	  p4: '转账完成后，填写并上传支付钱包地址和支付凭证截图，点击“保存发送”',
	  p5: '（建议使用USDT充值，免手续费，营业时间充值提现实时到账）'
  },
  
  help: {
	p1: '人工智能（AI）量化交易机器人的工作原理',
	p1_1: '以低价购买一种产品并以更高的价格将其出售到某个地方的方法变得越来越具有挑战性。由于目前比特币交易网络的拥堵状况以及高额的转账费用，市场的低效率将导致利润空间暴涨。<br>例如，Binance 和 Gate 的 BTC/USDT 交易仍然存在价格差异。如果您在 Gate 购买 BTC，然后将其发送到 Binance 并立即出售，您可以通过套利获得更多的 USDT，然后您将 USDT 从 Binance 发送到 Gate 并购买 BTC。这个循环不断重复，从价格差异中获利。&nbsp;',
	p2: '什么是智能财务管理？',
	p2_1: '智能财务管理代表着通过机器集成人工智能（AI）对简单的传统财务程序或计划者进行财务管理、替代或补充的投资。作为量化交易投资技术的延伸，是一种全球化的、创新的、新兴的、可持续的管理方式，具有广阔的投资远见，通过长期固定的投入来稀释持有成本以达到盈利目标<br>事实证明，为财务经理带来更多的财务规划前景和丰厚的利润。',
	p3: '智能机器人的优势',
	p3_1: '1，克服人性的弱点，没有贪婪和恐惧，纪律性强，严格执行投资策略，不受投资者情绪变化的影响。&nbsp;<br>2，模型的系统性：多层次量化模型、多角度观测和海量数据处理，结合大数据处理技术，捕捉更多投资机会。<br>3，及时、快速、准确：及时、快速地跟踪市场变化，不断发现能够提供超额收益的新统计模型，寻找新的交易机会，同时确保订单准确，这是主观交易无法比拟的。',
  },
  tips: {
	  p1: '机器人是及时的，请充分运行机器人以赚取更多收入',
	  p2: '机器人等级越高，下单时间越高效，盈利越多',
  },
  user: {
	  haimeizhanghu: '还没有帐户',
	  yonghuming: '用户名',
	  mima: '密码',
	  ninyoumeiyzhanghu: '你有没有账号',
	  qingshurnindeyonghuming: '请输入您的用户名',
	  querenmima: '确认密码',
	  qingshumima: '请输入密码',
	  qingshurumima: '请输入确认密码',
	  qingshuruyaoqingma: '请输入邀请码',
	  yuebuzu: '余额不足',
	  huiyuandengji: '{n}级会员',
	  levjidaili: '{n}级代理',
	  denglu: '登录',
	  xiugaidenglumima: '修改登录密码',
	  yuanshimima: '原始密码',
	  qingshuruyuanima: '请输入原密码',
	  xinmima: '新密码',
	  qinghuruxinmima: '请输入新密码',
	  liangcishurumimabuyizhi: '两次输入密码不一致',
	  
	  denglumima: '登录密码',
	  qingshurudenglumima: '请输入登录密码'
  },
  about: {
	  p1: 'Koen Ai 是美国纳斯达克上市的互联网券商、数字加密货币的渠道商！旗下拥有美国券商牌照以及数字货币经营牌照，获美国盈透战略投资。Koen ai为用户提供数字加密货币交易，给投资者提供一流的全球投资体验！',
	  p2: '通过机器集成人工智能（AI）对简单的传统财务程序或计划者进行财务管理、替代或补充的投资。作为量化交易投资技术的延伸，是一种全球化的、创新的、新兴的、可持续的管理方式，具有广阔的投资远见，通过长期固定的投入来稀释持有成本以达到盈利目标<br>事实证明，为财务经理带来更多的财务规划前景和丰厚的利润',
	  p3: 'Koen BOT已经获得美国MSB牌照许可',
	  p4: '多层次量化模拟，多角度观察，结合大数据处理分析技术，捕捉更多投资机会。',
	  p5: 'Koen Ai智能机器人可以24小时连续工作。 （无需手动设置，效率高）无需看行情，智能投资更简单、更理性。 （适用于大部分人，没有投资经验的也可以放心使用）'
  },
  proxyRule: {
	  p1: '代理返佣',
	  p2: '邀请1个人加入就可以享受1级会员 盈利的15%的下级返佣，您的下级会员邀请好友，您可以得到二级会员5%的下级返佣以此类推，直到第三级会员，您可以得到3%总共23%的下级返佣',
	  p3: '这是在Koen Ai 为了开发市场增加的非常丰厚的福利，每一个越早加入代理的成员，往往随着团队的增长，收入也会快速的增长，现在加入代理是非常好的一个时机。并且你的代理返佣是和下级盈利实时到账的，你的代理盈利随时可以提现或者继续投资，我相信每一位团队中不错的代理在接下来几年有非常高的收入，有机会让你在虚拟货币最赚钱的时期赚到第一个一百万。',
  },
  guanbi: '关闭',
  gonggao: '公告',
  
  
  genggaizijinmima: "更改资金密码",
  yinhangmingcheng: '银行名称',
  zhanghumingcheng: '帐户名称',
  kahao: "卡号",
  lianxidianhua: '联系电话',
  kaisheyinhangzhanghu: '开设银行账户',
  shangchuanfukuanjietu: '上传付款截图',
  qingxuanzeyinhangka: '请选择银行卡',
  xuanzeyanhang: '选择银行',
  tijiao: '提交',
  qingshuruyinhangmingcheng: '请输入银行账号名称',
  qingshuruyinhangkahao: '请输入银行卡号',
  qingshurulianxidianhua: '请输入联系电话',
  tixianmima: '提现密码',
  qingshurutixianmima: '请输入提现密码',
  huodong: '活动',
  zijinmima: '资金密码',
  qingshurujinjinmima: '请输入资金密码',
  querenzijinmima: '确认资金密码',
  qingshuruquerenzijinimma: '请输入确认资金密码',
  huodongneirong: '活动内容',
  qingshuruyinhangmingchengexuanze: '请输入银行名称',
  xiayiye: '下一页'
}
