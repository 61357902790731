import { get, post } from './http'


export const apiConfigData = p => get('api/index/configData', p);
export const apiExchangeRate = p => get('api/index/getExchangeRate', p);

export const apiGetRechargeBank = p => get('api/index/getRechargeBank', p);


export const apiGetRechargeBankInfo = p => get('api/index/getRechargeBankInfo', p);
export const apiGetWithdrawBankList = p => get('api/index/getWithdrawBankList', p);


export const apiUploadFile = p => post('api/upload/uploadFile', p);


export const apiGetActivityCategory = p => get('api/activity/getActivityCategory', p);
export const apiGetActivityList = p => get('api/activity/getList', p);
export const apiGetActivityInfo = p => get('api/activity/info', p);


export const apiUserInfo = p => get('api/user/info', p);
export const apiGetIslogin = p => get('api/user/islogin', p);
export const apiGetUsertrx = p => get('api/user/usertrx', p);
export const apiGetUserOrderDes = p => get('api/user/userOrderDes', p);
export const apiUpuserusdt = p => get('api/user/upuserusdt', p);
export const apiGetUserBankList = p => get('api/user/getUserBankList', p);
export const apiGetUserBankInfo = p => get('api/user/getUserBankInfo', p);




export const apiRechargeBuy = p => post('api/user/rechargeBuy', p);
export const apiRechargeOnepay= p => post('api/user/rechargeOnepay', p);

export const apiUserSaveBackInfo= p => post('api/user/userSaveBackInfo', p);



export const apiUserMessageDetail = p => get('api/user/userMessageDetail', p);
export const apiUserMessageList = p => get('api/user/userMessageList', p);
export const apiGetfundingRecords = p => get('api/user/funding_records', p);
export const apiGetWithdrawRecords = p => get('api/user/withdraw_record', p);
export const apiGetRechargeRecords = p => get('api/user/recharge_record', p);
export const apiGetAgentFundingRecords = p => get('api/user/agent_funding_records', p);
export const apiGetActingRechargelist = p => get('api/user/actingRechargelist', p);



export const apiGetAgentInfo = p => get('api/user/agentInfo', p);
export const apiActinglist = p => get('api/user/actinglist', p);


export const apiWithdrawBuy = p => post('api/user/withdrawBuy', p);

export const apiUpdateInfo = p => post('api/user/updateInfo', p);

export const apiChangePassword = p => post('api/user/changePassword', p);

export const apiChangePayPassword = p => post('api/user/changePayPassword', p);

export const apiLoginLogin = p => post('api/user/login', p);
export const apiLoginRegister = p => post('api/user/register', p);


export const apiFundCategory = p => get('api/fund/fundCategory', p);
export const apiFundOrder = p => post('api/fund/fundOrder', p);
export const apiFundOrderList = p => get('api/fund/orderlist', p);
