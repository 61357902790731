module.exports = {
  zhuye: 'homepage',
  tuandui: 'team',
  zizhan:'finanziamenti',
  lang_Zh: 'Cinese',
  lang_En: 'inglese',
  lang_Lt: 'Italiano',
  nihao: 'Ciao',
  fuzhi: 'copia',
  loadingText: 'Caricando...',
  loosingText: 'Rilasciare per rinfrescare...',
  pullingText: 'Scorri verso il basso per rinfrescare...',
  shijijine: 'Importo effettivo del pagamento',
  jiaoyihaxi: 'hash della transazione',
  chengongdingou: 'ordine riuscito',
  jinrigerenshouru: 'reddito personale oggi',
  touzizqdybsr: 'Investi e guadagna il tuo primo reddito',
  diyibitouzi: 'inizia il tuo primo investimento',
  jiqiren: 'robot',
  chakanwodejiqirenck: 'Visualizza il mio magazzino di robot',
  wodejiqirenshuliang: 'numero dei miei bot',
  goumaihetong: 'Contratto di acquisto',
  jiage: 'prezzo',
  youxiaoqi: 'periodo di validità',
  yujirishouru: 'Beneficio totale stimato',
  jintian: 'oggi',
  benxingqi: 'questa settimana',
  huoqushijian: 'Tempo di attivazione',
  zuihoujihuoshijian: "Orario di chiusura",
  jingguoshijian: 'Tempo passato',
  weiyidaima: 'codice univoco',
  tian: 'Cielo',
  shi: 'Orario',
  fen: 'Minuto',
  ren: 'Persone',
  qidongzijin: 'Importo di avviamento',
  fuwu:'Servire',
  fenxiangyaoqing: 'condividi invito',
  chongzhi: 'ricarica',
  tixian: 'Prelievi',
  zaixianfuwu: 'Servizio Online',
  bangzhuzhinan: 'guida di aiuto',
  guanyuwomen: 'riguardo a noi',
  hezuohuoban: 'partner di cooperazione',
  dangqianyue: 'saldo attuale',
  zhifu: 'per pagare',
  wenxitishi: 'Suggerimenti gentili',
  hangqing: 'Tendenza',
  muqiangongyountaijiqirenxiadan:"Al momento c'è {n} robot che effettua un ordine automaticamente",
  dangqianjiqirendengji: 'Livello robot attuale',
  zhuangtai: 'stato',
  zhengchang: 'normale',
  zanwushuju: 'Temporaneamente nessun dato',
  qujiaoyisuo: 'vai allo scambio',
  wodetuandui: 'la mia squadra',
  tuanduichongzhi: 'Ricarica della squadra',
  tuijianguize: 'Regole',
  huiyuanjianjie: 'Profilo membro',
  wodezichan: 'i miei beni',
  zongyunyingzijin: 'capitale circolante totale',
  wodeqianbaoyue: 'il saldo del mio portafoglio',
  gengduo: 'Altro',
  tuanduijianglu: 'ricompensa della squadra',
  kuanjishouyi: 'Guadagni del robot',
  dailifanyong: 'Commissione di agenzia',
  quanbu: 'tutti',
  levexiajidingdan: 'Ordini subordinati di livello {n}',
  yaoqingma: 'Codice invito',
  gerenshouruleiji: 'reddito individuale accumulato',
  jinrishouruleiji: 'Reddito di agenzia oggi',
  dailileijishouru: 'Reddito cumulativo di agenzia',
  jiqirenhuikou: 'rimborso del robot',
  wodeGcash: 'Il mio indirizzo del portafoglio',
  jiaoyijilu: 'Registro delle transazioni',
  shourujilu: 'record di reddito',
  feiyongjilu: 'registro spese',
  tikuanjilu: 'Cronologia dei prelievi',
  chunkuanjilu: 'registro di deposito',
  chunkuanqudao: 'canale di deposito',
  fankui: 'il feedback',
  chunkuanshuoming: 'illustra',
  genggaidenglumima: 'cambiare la password di accesso',
  dengchu: 'Disconnettersi',
  fuzhichengg: 'copia con successo',
  fuzhishibai: "la replica non è riuscita",
  dailifanli: 'Commissione di agenzia',
  meiyougengduo: 'niente di più',
  jine: 'Quantità di fondi',
  shouxufei: 'tassa di gestione',
  tixianqudao: 'Canale di prelievo contanti',
  shenqingshijian: 'tempo di applicazione',
  qingshurudizhi: 'Inserisci il tuo indirizzo',
  zhichu: 'dispendio',
  qingshurujine: "Si prega di inserire l'importo",
  tianjia: 'Aggiungere a',
  baochou: 'remunerazione',
  shijian: 'tempo',
  yaoqingguize: 'regole di invito',
  yaoqingpengyou: 'Invita gli amici',
  dailimoshi: 'Modalità proxy',
  yaoqingpengyouyiqizhuanqian: 'Invita gli amici a fare soldi insieme',
  fanlishengjiyaoqinghaoyou: "Aggiorna lo sconto, invita gli amici a condividere fino al 16% di sconto sulle transazioni e diventa un capo con gli amici!",
  tuijianlianjie: 'Link di riferimento',
  wodejiqiren: 'il mio robot',
  yimaichujiqiren: 'Robot che sono stati venduti',
  zaigongzuo: 'al lavoro',
  yimaichu: 'già venduta',
  laiyuan: 'origine',
  hetonggoumaai: 'Contratto di acquisto',
  wodexiaoxi: 'mie notizie',
  xiaoxixiangqing: 'dettagli del messaggio',
  dengji: 'per registrarsi',
  lijichuangjianzhanghu: 'crea un account ora',
  miaokuaisukaihu: 'Apertura rapida del conto in 30 secondi',
  xuanzeyuyan: 'Scegli una lingua',
  queren: 'Confermare',
  quxiao: 'Annulla',
  
  bangzhuzhongxin: 'centro di aiuto',
  chuangjianwenti: 'problema comune',
  qianbaodizhi: 'indirizzo del portafoglio',
  qingshuruqianbaodizhi:"Si prega di inserire l'indirizzo del portafoglio",
  qianbaodizhibangding: "Vincolo dell'indirizzo del wallet",
  xuanzetikuanfangshi: 'Scegli un metodo di prelievo',
  qiangleituijian20: 'Consiglio vivamente TRC20',
  chenggongzhifu: 'pagamento andato a buon fine',
  tixiantishi: "Suggerimenti per il prelievo: </br>\
		  1. Le transazioni di prelievo richiedono da 5 minuti a 1 ora per raggiungere il tuo conto.</br>\
		  2. E l'orario di prelievo è dalle 11:00 alle 23:00 se non ricevi il denaro prelevato per qualsiasi motivo. Si prega di contattare il servizio clienti.</br>\
		  3. L'importo minimo di prelievo è di 30 baht. Puoi prelevare 1 volta al giorno.</br>",
  pay: {
	  t1: "Si prega di utilizzare un portafoglio abilitato per Tron per scansionare il codice QR o copiare e incollare l'importo e l'indirizzo nel portafoglio per il pagamento",
	  p1: "Se utilizzi il portafoglio TP per il trasferimento, seleziona il tipo di valuta come USDT. Si consiglia di utilizzare scambi e trasferimenti imtoken.",
	  p2: "Dopo che il pagamento è andato a buon fine, di solito ci vuole circa un minuto per arrivare al conto",
	  p3: "Fare clic sull'importo e sull'area dell'indirizzo da copiare direttamente",
	  p4: "L'importo del pagamento deve essere coerente con l'importo dell'ordine visualizzato sopra, altrimenti il ​​sistema potrebbe non ricevere l'account."
  },
  inst: {
	  t: 'Usa USDT',
	  p1: 'Scegli il metodo USDT',
	  p2: `Compila il saldo (USDT) che deve essere ricaricato nella colonna del saldo e fai clic sul pulsante "Conferma" in basso`,
	  p3: "Dopo aver ottenuto l'indirizzo del portafoglio CALA, trasferisci USDT (TRC20) tramite l'app del portafoglio crittografato",
	  p4: `Una volta completato il trasferimento, compilare e caricare l'indirizzo del portafoglio di pagamento e lo screenshot del voucher di pagamento, fare clic su "Salva e invia"`,
	  p5: `(Si consiglia di utilizzare USDT per ricaricare, senza spese di gestione, e la ricarica arriverà in tempo reale durante l'orario lavorativo)`
  },
  
  help: {
	p1: 'Come funzionano i robot di trading quantitativo di intelligenza artificiale (AI).',
	p1_1: `Il metodo per acquistare un prodotto a un prezzo basso e venderlo da qualche parte a un prezzo più alto sta diventando sempre più impegnativo. A causa dell'attuale congestione della rete di trading di Bitcoin e delle elevate commissioni di trasferimento, l'inefficienza del mercato porterà a margini di profitto alle stelle. <br>Ad esempio, c'è ancora una differenza di prezzo tra Binance e Gate per le transazioni BTC/USDT. Se acquisti BTC su Gate, poi lo invii a Binance e lo vendi immediatamente, puoi ottenere più USDT attraverso l'arbitraggio, quindi invii USDT da Binance a Gate e acquisti BTC. Questo ciclo si ripete, traendo vantaggio dalle differenze di prezzo. &nbsp;`,
	p2: `Cos'è la gestione finanziaria intelligente?`,
	p2_1: `La gestione finanziaria intelligente rappresenta un investimento nella gestione finanziaria, sostituzione o integrazione di semplici programmi o pianificatori finanziari tradizionali attraverso l'intelligenza artificiale (AI) integrata nella macchina. Come estensione della tecnologia di investimento di trading quantitativo, è un metodo di gestione globale, innovativo, emergente e sostenibile con un'ampia previsione di investimento, che diluisce i costi di detenzione attraverso investimenti fissi a lungo termine per raggiungere obiettivi di profitto<br> Ha dimostrato di portare a maggiori investimenti finanziari prospettive di pianificazione e buoni profitti per i gestori finanziari.`,
	p3: 'Vantaggi dei robot intelligenti',
	p3_1: `1. Superare la debolezza della natura umana, non avere avidità e paura, essere disciplinato, attuare rigorosamente strategie di investimento e non essere influenzato dai cambiamenti nel sentimento degli investitori. &nbsp;<br>2. Modello sistematico: modello quantitativo multilivello, osservazione multiangolare ed elaborazione massiccia dei dati, combinati con la tecnologia di elaborazione dei big data, per cogliere maggiori opportunit&agrave; <br>3. Tempestivo, veloce e accurato: monitora i cambiamenti del mercato in modo tempestivo e veloce, scopri costantemente nuovi modelli statistici in grado di fornire rendimenti extra, trova nuove opportunità di trading e assicurati ordini accurati, che non sono paragonabili alle transazioni soggettive.`
  },
  tips: {
	  p1: 'Il bot è tempestivo, esegui il bot completamente per guadagnare più entrate',
	  p2: `Più alto è il livello del robot, più efficiente è il tempo dell'ordine e maggiore è il profitto`,
  },
  user: {
	  haimeizhanghu: 'nessun account ancora',
	  yonghuming: `Nome utente`,
	  mima: 'Password',
	  ninyoumeiyzhanghu: 'Hai un account',
	  qingshurnindeyonghuming: 'Inserisci il tuo nome utente',
	  querenmima: 'Conferma password',
	  qingshumima: 'Per favore, inserisci la password',
	  qingshurumima: 'Si prega di inserire la password di conferma',
	  qingshuruyaoqingma: 'Inserisci il codice di invito',
	  yuebuzu: 'Equilibrio insufficiente',
	  huiyuandengji: 'Membro di livello {n}',
	  levjidaili: 'Livello {n}',
	  denglu: 'Login',
	  xiugaidenglumima: 'Modifica password di accesso',
	  yuanshimima: `parola d'ordine originale`,
	  qingshuruyuanima: 'Si prega di inserire la password originale',
	  xinmima: 'nuova password',
	  qinghuruxinmima: 'Inserisci una nuova password',
	  liangcishurumimabuyizhi: 'Le password immesse due volte non sono coerenti',
	  denglumima: 'Password di accesso',
	  qingshurudenglumima: 'Inserisci la tua password di accesso'
	  
  },
  about: {
  	  p1: `Koen Ai è un broker Internet quotato al NASDAQ negli Stati Uniti e un fornitore di canali di criptovaluta digitale! Ha una licenza di intermediazione negli Stati Uniti e una licenza operativa di valuta digitale e ha ricevuto investimenti strategici da Interactive Brokers. Koen ai offre agli utenti transazioni di criptovaluta digitale e offre agli investitori un'esperienza di investimento globale di prima classe!`,
  	  p2: `Investimenti nella gestione finanziaria, sostituzione o complemento di semplici programmi o pianificatori finanziari tradizionali mediante intelligenza artificiale (AI) integrata nella macchina. Come estensione della tecnologia di investimento di trading quantitativo, è un metodo di gestione globale, innovativo, emergente e sostenibile con un'ampia previsione di investimento e diluisce i costi di detenzione attraverso investimenti fissi a lungo termine per raggiungere obiettivi di profitto<br> Ha dimostrato di offrire maggiori prospettive di pianificazione finanziaria e grandi profitti ai gestori finanziari`,
  	  p3: 'Koen BOT ha ottenuto la licenza US MSB',
  	  p4: `Simulazione quantitativa multilivello, osservazione multiangolare, combinata con l'elaborazione di big data e la tecnologia di analisi, per cogliere maggiori opportunità di investimento.`,
  	  p5: ` Il robot intelligente Koen Ai può funzionare ininterrottamente per 24 ore. (nessuna impostazione manuale, alta efficienza)
Non c'è bisogno di guardare al mercato, gli investimenti intelligenti sono più semplici e razionali. (Applicabile alla maggior parte delle persone, puoi usarlo con fiducia se non hai esperienza di investimento)`
  },
  proxyRule: {
  	  p1: 'Sconto agente',
  	  p2: `Invita 1 persona a unirsi, puoi godere del 15% dello sconto di livello inferiore del profitto del membro di primo livello,
Quando il tuo membro di livello inferiore invita gli amici, puoi ottenere una commissione di livello inferiore del 5% del membro di secondo livello
Analogamente, fino al membro di terzo livello, puoi ottenere il 3% e un totale del 23% della commissione di livello inferiore`,
  	  p3: `Questo è un vantaggio molto generoso aggiunto da Koen Ai al fine di sviluppare il mercato. Ogni membro che entra a far parte dell'agenzia in precedenza avrà spesso un rapido aumento del reddito man mano che il team cresce. Ora è un ottimo momento per entrare a far parte dell'agenzia. E gli sconti della tua agenzia vengono accreditati in tempo reale sui profitti dei tuoi subordinati e i profitti della tua agenzia possono essere ritirati o continuare a investire in qualsiasi momento.Credo che ogni buon agente del team avrà un reddito molto elevato nei prossimi anni e avere l'opportunità di farti guadagnare il tuo primo milione durante il periodo più redditizio della valuta virtuale.`
  },
  guanbi: 'chiusura',
  gonggao: 'annuncio',
  genggaizijinmima: "Modificare la password finanziaria"
}
