import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/index.vue'
import TabbarIndexView from '../views/tabbar/index.vue'
import TabbarActivityView from '../views/tabbar/activity.vue'
import TabbaRrobotRunPageView from '../views/tabbar/robot-run-page.vue'
import TabbaUcTeamView from '../views/tabbar/uc-team.vue'
import TabbaUcenterView from '../views/tabbar/ucenter.vue'

import MessageListView from '../views/message/list.vue'
import MessageDetailView from '../views/message/detail.vue'

import UserDepositView from '../views/user/deposit.vue'
import UserDepositSubView from '../views/user/deposit-sub.vue'



import UserRechargeInstructionsView from '../views/user/recharge-instructions.vue'
import UserWithdrawalView from '../views/user/withdrawal.vue'
import UserBindWalletView from '../views/user/bind-wallet.vue'
import UserBankView from '../views/user/bank.vue'
import UserBankBindView from '../views/user/bank-bind.vue'
import UserTradeRecordView from '../views/user/trade-record.vue'
import UserIncomeView from '../views/user/income.vue'
import UserCostView from '../views/user/cost.vue'
import UserWithdrawRecordView from '../views/user/withdraw-record.vue'
import UserCunkRecordView from '../views/user/cunk-record.vue'
import UserPaymentView from '../views/user/payment.vue'

import HelpView from '../views/help/help.vue'
import TeamView from '../views/team/team.vue'
import TeamProxyView from '../views/team/proxy.vue'
import TeamRuleView from '../views/team/tm-rule.vue'
import TeamUseInfoView from '../views/team/tm-use-info.vue'
import TeamUseRechargeView from '../views/team/tm-use-recharge.vue'
import TeamAgentPageView from '../views/team/agent-page.vue'

import RobotView from '../views/robot/robot.vue'
import AboutView from '../views/about/about.vue'
import CustomerView from '../views/customer/customer.vue'
import loginView from '../views/login/login.vue'
import loginFindPwdView from '../views/login/find-pwd.vue'
import loginRegistView from '../views/login/regist.vue'
import loginPwdView from '../views/login/login-pwd.vue'
import loginFundingView from '../views/login/funding-pwd.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
	name: 'index',
    component: IndexView,
	children: [
	  {
		  path: '/',
		  name: 'home',
		  component: TabbarIndexView,
	  },
	  {
		  path: '/robot-run-page',
		  name: 'robot-run-page',
		  component: TabbaRrobotRunPageView,
	  },
	  {
		  path: '/activity',
		  name: 'activity',
		  component: TabbarActivityView,
	  },
	  
	  {
		  path: '/uc-team',
		  name: 'uc-team',
		  component: TabbaUcTeamView,
	  },
	  {
		  path: '/ucenter',
		  name: 'ucenter',
		  component: TabbaUcenterView,
	  },
	  
	]
  },
  {
    path: '/message-detail',
    name: 'message-detail',
    component: MessageDetailView
  },
  
  {
    path: '/message-list',
    name: 'message-list',
    component: MessageListView
  },
  {
    path: '/user-deposit',
    name: 'user-deposit',
    component: UserDepositView
  },
  {
    path: '/user-deposit-sub',
    name: 'user-deposit-sub',
    component: UserDepositSubView
  },
  {
    path: '/user-recharge-instructions',
    name: 'user-recharge-instructions',
    component: UserRechargeInstructionsView
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: UserWithdrawalView
  },
  {
    path: '/bank',
    name: 'bank',
    component: UserBankView
  },
  {
    path: '/bank-bind',
    name: 'bank-bind',
    component: UserBankBindView
  },
  {
    path: '/trade-record',
    name: 'trade-record',
    component: UserTradeRecordView
  },
  {
    path: '/income',
    name: 'income',
    component: UserIncomeView
  },
  {
    path: '/cost',
    name: 'cost',
    component: UserCostView
  },
  {
    path: '/withdraw-record',
    name: 'withdraw-record',
    component: UserWithdrawRecordView
  },
  
  {
    path: '/payment',
    name: 'payment',
    component: UserPaymentView
  },
  {
    path: '/bind-wallet',
    name: 'bind-wallet',
    component: UserBindWalletView
  },
  {
    path: '/cunk-record',
    name: 'cunk-record',
    component: UserCunkRecordView
  },
  
  
  
  {
    path: '/customer',
    name: 'customer',
    component: CustomerView
  },
  
  {
    path: '/help',
    name: 'help',
    component: HelpView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  
  {
    path: '/robot',
    name: 'robot',
    component: RobotView
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView
  },
  {
    path: '/team-proxy',
    name: 'team-proxy',
    component: TeamProxyView
  },
  {
    path: '/tm-rule',
    name: 'tm-rule',
    component: TeamRuleView
  },
  
  {
    path: '/tm-use-info',
    name: 'tm-use-info',
    component: TeamUseInfoView
  },
  {
    path: '/tm-use-recharge',
    name: 'tm-use-recharge',
    component: TeamUseRechargeView
  },
  
  {
    path: '/agent-page',
    name: 'agent-page',
    component: TeamAgentPageView
  },
  
  {
    path: '/login',
    name: 'login',
    component: loginView
  },
  {
    path: '/find-pwd',
    name: 'find-pwd',
    component: loginFindPwdView
  },
  {
    path: '/login-pwd',
    name: 'login-pwd',
    component: loginPwdView
  },
  {
    path: '/funding-pwd',
    name: 'funding-pwd',
    component: loginFundingView
  },
  
  
  {
    path: '/regist',
    name: 'regist',
    component: loginRegistView
  },
  
  
]

const router = new VueRouter({
  routes
})

export default router
