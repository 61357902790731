<template>
	<div class="page" style="min-height: 100vh;">
		<van-nav-bar :title="$t('tuijianguize')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />

	  <div class="info">
		<p>{{$t('proxyRule.p1')}}</p>
		<p>{{$t('proxyRule.p2')}}</p>
		<p>{{$t('proxyRule.p3')}}</p>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
	
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>
<style scoped>
	.info {
		padding: .4rem;
		color: #333;
		font-size: .3rem;
	}
	.info p {
		margin-bottom: .3rem;
	}
</style>