<template>
	<div class="proxy-page">
		<van-nav-bar :title="$t('yaoqingguize')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
	  
	  <div class="page-card">
	    <div>
	      <div class="proxy-contnt">
	        <p>
	          <img src="https://koentestapi.xn--noexcellent-cbb.com/upload/20220223/IOp1oOp5PEq9WWo86PqVZ9boupBqEPZW.png" style="max-width:100%;" contenteditable="false"></p>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
	
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style>
</style>