<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('genggaizijinmima')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />

		<div class="wrapper">
			<form class="bs-form van-form">
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>{{$t('user.denglumima')}}</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="password" 
							v-model="formData.password" 
							:placeholder="$t('user.qingshurudenglumima')" 
							class="van-field__control">
						</div>
					</div>
				</div>
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>{{$t('zijinmima')}}</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="password" v-model="formData.payment_password" :placeholder="$t('qingshurujinjinmima')" class="van-field__control">
						</div>
					</div>
				</div>
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>{{$t('querenzijinmima')}}</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="password" v-model="formData.payment_qrpassword" :placeholder="$t('qingshuruquerenzijinimma')" class="van-field__control">
						</div>
					</div>
				</div>
				<div class="btn-fixed">
					<van-button class="queren"  @click="onSubmit">{{$t('queren')}}</van-button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	
	import {
		apiChangePayPassword
	} from '@/request/api'; //api接口
	
	
	export default {
		data() {
			return {
				formData: {
					payment_password: '',
					password: '',
					payment_qrpassword: ''
				}
			}
		},
		methods: {
			onSubmit() {
				if (!this.formData.password) {
					
					this.$toast(this.$t('user.qingshurudenglumima'));
					return false;
				}
				if (!this.formData.payment_qrpassword) {
					
					this.$toast(this.$t('user.qinghuruxinmima'));
					return false;
				}
				
				if (this.formData.payment_password != this.formData.payment_qrpassword) {
					this.$toast(this.$t('user.liangcishurumimabuyizhi'));
					return false;
				}
				
				apiChangePayPassword(this.formData).then(res => {
					if (res.code != 0) {
						this.$toast(res.msg);
						return false;
					}
					
					this.$toast(res.msg);
					
					setTimeout(()=>{
						this.$router.push('/ucenter')
					},2000)
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {
	
			}
		}
	}
</script>

<style scoped>
	.queren{
		color:#000;
		width:100%;
		height:.96618rem;
		border-radius:.24155rem;
		border:none;
		background-color:#ffc100;
	}
</style>
