<template>
	<div class="page">

	  
	  <van-nav-bar :title="$t('wodexiaoxi')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
	  <div class="wrapper">
		  
		<van-pull-refresh 
			:pulling-text="$t('pullingText')"
			:loosing-text="$t('loosingText')"
			:loading-text="$t('loadingText')"
			v-model="refreshing" @refresh="onRefresh">
			<van-list :loading-text="$t('loadingText')" v-model="loading" :finished="finished" :finished-text="$t('meiyougengduo')" @load="onLoad">
		
		
				<div class="agent-list">
					<div class="msg-list" :key="index" v-for="(item,index) in fundingRecords" 
					@click="onMessageDetailUrl(item.id)">
					  <div class="pd-20 ac-diglog">
					    <div class="flex">
					      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAeNJREFUSInd1L1rFEEYx/Hvs7veHYjCKUkVFfciwYtIIJYWtirqLfFAC4lFIGCpoIWNhUSs1BA7Cw/SaVzFf0BQCyGCryjRuyiohSJBUBPY3XksIpJ73cxho0/3PLPz+zAMO/CvlzRN/JF9YE4CRVRPM397+u8BhVIJ5CpwEfUqLC1FZNbsBM2S8d4yd+OjLeDVdUYCxCsicRaNr5DzjoJmAIhj8IMnqF5gfugmnDP2J+grbyBjNoJ5APR22DZD7VYZ0DTAres2DSbEyX1gS8q+IvntwsLre2mAU9ctJieAgbRNv+ss/sg2OwA9vMpwABfMJUuAHRYAwH76S3tsgKwlAEambIA31gAM4gezy/9QOhB2AQAMoxLiBy/ZeuhAe8BzKkDSJQJQRJzz7YG5mRow2WX4d0TPkO/ZtXLY/NgNHFxH5FaBHovwaYRTVMPPjQvNAEAhGEW5vorgpxgzzrs7j9p90HjJy1UNK6A/2sYqXxEdpxYO4bgdH73WAAjI2hbzBJEpxCsAj/GDWZSxToDXctpXzkPcOH2IccaQaAExl1EZBSKMHrM/QS7Kr+g+IHKEWrgb1W+I+xzkOPAFlTLvw1edgHaX3IvyDOEai/EEn+7+/LPWv3c9mttMNXzRKfj/qV8LS4rACA72eQAAAABJRU5ErkJggg==" alt="">
					      <span class="msg-badge" v-if="item.isRead == 0">1</span>
					      <span class="tit">{{item.title}}</span>
					      <span class="text-7a8">{{item.create_time}}</span>
					    </div>
					  </div>
					</div>
					
				</div>
		
			</van-list>
		</van-pull-refresh>
		  
	  
	  </div>
	</div>
</template>

<script>
	import {
		apiUserInfo,
		apiUserMessageList
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				fundingRecords: [],
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				active: 0,
			}
		},
		created() {
			this.getfundingRecords()
		},
		methods: {
			onLoad() {
				console.log(1);
				this.page++;
				this.getfundingRecords()
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
	
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				// this.refreshing = true;
				this.fundingRecords = [];
				this.page = 1;
				this.getfundingRecords();
			},
			getfundingRecords() {
				apiUserMessageList({
					page: this.page
				}).then(res => {
					// this.fundingRecords = res.data.data;
					if (this.fundingRecords.length >= res.data.count) {
						this.finished = true;
					}
					this.fundingRecords = [...this.fundingRecords, ...res.data.data]
					this.loading = false;
					this.refreshing = false
	
	
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {},
			onMessageDetailUrl(id) {
				this.$router.push('/message-detail?id='+id)
			}
		}
	}
	
</script>

<style scoped>
	.msg-badge {
		position:relative;
		top:-.24155rem;
		left:-.36232rem;
		width:.48309rem;
		height:.48309rem;
		line-height:.48309rem;
		text-align:center;
		border-radius:50%;
		background-color:#0014cd;
		color:#fff;
		font-size:.24155rem
	}
	.wrapper {
		background:#fff;
		min-height:calc(100vh - 1.44928rem)
	}
	.msg-list {
		border-radius:.19324rem;
		background:#f3f6fb;
		margin-top:.48309rem;
		font-size:.33816rem
	}
	.flex {
		display:flex;
		align-items:center;
		justify-content:space-between
	}
	.pd-20 {
		padding:.36232rem .24155rem
	}
	.text-7a8 {
		text-align:right;
		width:3.86473rem;
		color:#7a869a
	}
	.moretb,.tit {
		font-size:.33816rem;
		color:#101010;
		width:4.83092rem;
		padding-left:.24155rem
	}
	.mt10 {
		margin-top:.24155rem
	}
	.bortopr {
		border-top:.02415rem dashed #979797
	}

</style>