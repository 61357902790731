<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('tikuanjilu')" :fixed="true" :placeholder="true" left-arrow @click-left="onClickLeft"
			@click-right="onClickRight" />


		

		<div class="wrapper" style="padding: 10px 5px;">
			<van-pull-refresh 
				:pulling-text="$t('pullingText')"
				:loosing-text="$t('loosingText')"
				:loading-text="$t('loadingText')"
				v-model="refreshing" @refresh="onRefresh">
				<van-list
				  v-model="loading"
				  :finished="finished"
				  :finished-text="$t('meiyougengduo')"
				  :loading-text="$t('loadingText')"
				  @load="onLoad"
				>
				  <div role="feed" class="van-list" style="padding: 10px;">
		
					<div class="wt-card" :key="index" v-for="(item,index) in fundingRecords">
						<div class="wt-status " 
						:class="item.status == 0 ? 'info' : (item.status == 1 ? 'success' : 'danger' ) ">{{item.statusTxt}}</div>
						<div class="wt-items">
							<div class="label">{{$t('jine')}}</div>
							<div class="value">{{item.price}}</div>
						</div>
						<div class="wt-items">
							<div class="label">{{$t('shouxufei')}}</div>
							<div class="value">{{item.feePrice}}</div>
						</div>
						<div class="wt-items">
							<div class="label">{{$t('tixianqudao')}}</div>
							<div class="value" style="font-size: 12px;">{{item.typeTxt}}</div>
						</div>
						<div class="wt-items">
							<div class="label">{{$t('shenqingshijian')}}</div>
							<div class="time">{{item.create_time}}</div>
						</div>
					</div>
								
				  </div>
				</van-list>
			</van-pull-refresh>

		</div>
	</div>
</template>

<script>
	import {
		apiUserInfo,apiGetWithdrawRecords
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				fundingRecords: [],
				loading: false,
				finished: false,
				refreshing: false,
				page: 0
			}
		},
		created() {
			// this.getfundingRecords()
		},
		methods: {
			onLoad() {
				console.log(1);
				this.page++;
				this.getfundingRecords()
			},
			onRefresh() {
			  // 清空列表数据
			  this.finished = false;
		
			  // 重新加载数据
			  // 将 loading 设置为 true，表示处于加载状态
			  this.loading = true;
			  // this.refreshing = true;
			  this.fundingRecords = [];
			  this.page = 1;
			  this.getfundingRecords();
			},
			getfundingRecords() {
				apiGetWithdrawRecords({page:this.page}).then(res => {
					// this.fundingRecords = res.data.data;
					if (this.fundingRecords.length >= res.data.count) {
						this.finished = true;
					}
					this.fundingRecords = [...this.fundingRecords,...res.data.data]
					this.loading = false;
					this.refreshing = false
					
					
				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {}
		}
	}
</script>

<style>
</style>
