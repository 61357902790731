<template>
	<div class="page-green">
		<van-nav-bar title="找回密码" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
		<div class="wrapper">
			<form class="bs-form van-form">
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">姓名</font>
							</font>
						</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="text" placeholder="请输入您的姓名" class="van-field__control">
						</div>
					</div>
				</div>
				<div class="field-phone van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">电话</font>
							</font>
						</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<div class="van-field__control van-field__control--custom">
								<div class="sw-label">
									<span class="lan-n">
										<font style="vertical-align: inherit;">
											<font style="vertical-align: inherit;">+63</font>
										</font>
									</span>
									<span class="iconfont icon-tree-node"></span>
								</div>
								<div class="van-cell van-field" style="margin-bottom: 0px;">
									<div class="van-cell__value van-cell__value--alone van-field__value">
										<div class="van-field__body">
											<input type="tel" inputmode="numeric" placeholder="请输入您的电话号码"
												class="van-field__control">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">验证码</font>
							</font>
						</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="text" placeholder="手机验证码" class="van-field__control">
							<div class="van-field__button">
								<button class="get-code van-button van-button--default van-button--normal">
									<div class="van-button__content">
										<span class="van-button__text">
											<font style="vertical-align: inherit;">
												<font style="vertical-align: inherit;">获取验证码</font>
											</font>
										</span>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">新密码</font>
							</font>
						</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="password" placeholder="请输入新密码" class="van-field__control">
						</div>
					</div>
				</div>
				<div class="van-cell van-field">
					<div class="van-cell__title van-field__label">
						<span>
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">确认密码</font>
							</font>
						</span>
					</div>
					<div class="van-cell__value van-field__value">
						<div class="van-field__body">
							<input type="password" placeholder="请输入确认密码" class="van-field__control">
						</div>
					</div>
				</div>
				<div class="btn-space">
					<button class="van-button van-button--submit van-button--normal van-button--block">
						<div class="van-button__content">
							<span class="van-button__text">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">重设密码</font>
								</font>
							</span>
						</div>
					</button>
				</div>
				<div class="form-link">
					<div>
						<font style="vertical-align: inherit;">
							<font style="vertical-align: inherit;">你有没有账号？</font>
						</font>
						<a href="#/login" class="">
							<font style="vertical-align: inherit;">
								<font style="vertical-align: inherit;">登录</font>
							</font>
						</a>
					</div>
				</div>
			</form>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {

			}
		}
	}
</script>

<style scoped>
	.page-green {
		background-image: none;
	}
</style>
