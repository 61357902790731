module.exports = {
  zhuye: 'home page',
  tuandui: 'team',
  zizhan:'assets',
  lang_Zh: 'Chinese',
  lang_En: 'English',
  lang_Lt: 'Italian',
  nihao: 'Hello',
  fuzhi: 'copy',
  loadingText: 'Loading...',
  loosingText: 'Release to refresh...',
  pullingText: 'Pull down to refresh...',
  shijijine: 'Actual payment amount',
  jiaoyihaxi: 'transaction hash',
  chengongdingou: 'Successfully ordered',
  jinrigerenshouru: 'personal income today',
  touzizqdybsr: 'Invest and earn your first income',
  diyibitouzi: 'start your first investment',
  jiqiren: 'robot',
  chakanwodejiqirenck: 'View my robot warehouse',
  wodejiqirenshuliang: 'number of my bots',
  goumaihetong: 'transaction contract',
  jiage: 'price',
  youxiaoqi: 'validity period',
  yujirishouru: 'Expected total revenue',
  jintian: 'today',
  benxingqi: 'this week',
  huoqushijian: 'Get time',
  zuihoujihuoshijian: 'Closing time',
  jingguoshijian: 'Elapsed time',
  weiyidaima: 'Unique Code',
  tian: 'Day',
  shi: 'hour',
  fen: 'Minute',
  ren: 'People',
  qidongzijin: 'Start-up capital',
  fuwu:'Serve',
  fenxiangyaoqing: 'share invitation',
  chongzhi: 'recharge',
  tixian: 'withdraw',
  zaixianfuwu: 'online service',
  bangzhuzhinan: 'help guide',
  guanyuwomen: 'about us',
  hezuohuoban: 'the partner',
  dangqianyue: 'current balance',
  zhifu: 'to pay',
  wenxitishi: 'Kind tips',
  hangqing: 'the market',
  muqiangongyountaijiqirenxiadan:'At present, there are {n} robots that place orders automatically',
  dangqianjiqirendengji: 'Current robot level',
  zhuangtai: 'state',
  zhengchang: 'the normal',
  zanwushuju: 'No data yet',
  qujiaoyisuo: 'go to the exchange',
  wodetuandui: 'my team',
  tuanduichongzhi: 'Team recharge',
  tuijianguize: 'recommended rules',
  huiyuanjianjie: 'Member Profile',
  wodezichan: 'my assets',
  zongyunyingzijin: 'total working capital',
  wodeqianbaoyue: 'my wallet balance',
  gengduo: 'More',
  tuanduijianglu: 'team reward',
  kuanjishouyi: 'Robot gains',
  dailifanyong: 'Agent Anti-Commission',
  quanbu: 'all',
  levexiajidingdan: 'Level {n} Subordinate Orders',
  yaoqingma: 'Invitation code',
  gerenshouruleiji: 'personal income accumulation',
  jinrishouruleiji: 'Agency income today',
  dailileijishouru: 'Agency cumulative income',
  jiqirenhuikou: 'Robot Rebates',
  wodeGcash: 'My Wallet Address',
  jiaoyijilu: 'Transaction Record',
  shourujilu: 'income record',
  feiyongjilu: 'expense record',
  tikuanjilu: 'Withdrawal history',
  chunkuanjilu: 'deposit record',
  chunkuanqudao: 'deposit channel',
  fankui: 'the feedback',
  chunkuanshuoming: 'Deposit instructions',
  genggaidenglumima: 'change login password',
  dengchu: 'Sign out',
  fuzhichengg: 'copy successfully',
  fuzhishibai: 'replication failed',
  dailifanli: 'Agent rebate',
  meiyougengduo: 'no more',
  jine: 'Amount',
  shouxufei: 'handling fee',
  tixianqudao: 'Cash withdrawal channel',
  shenqingshijian: 'application time',
  qingshurudizhi: 'Please enter your address',
  zhichu: 'expenditure',
  qingshurujine: 'Please enter the amount',
  tianjia: 'Add to',
  baochou: 'remuneration',
  shijian: 'time',
  yaoqingguize: 'invitation rules',
  yaoqingpengyou: 'Invite friends',
  dailimoshi: 'Proxy mode',
  yaoqingpengyouyiqizhuanqian: 'Invite friends to make money together',
  fanlishengjiyaoqinghaoyou: 'Rebate upgrade, invite friends to share up to 16% transaction rebate, and be a boss with friends!',
  tuijianlianjie: 'referral link',
  wodejiqiren: 'my robot',
  yimaichujiqiren: 'Robot sold',
  zaigongzuo: 'at work',
  yimaichu: 'has been sold',
  laiyuan: 'source',
  hetonggoumaai: 'contract purchase',
  wodexiaoxi: 'my news',
  xiaoxixiangqing: 'Message Details',
  dengji: 'registration',
  lijichuangjianzhanghu: 'Create account now',
  miaokuaisukaihu: 'Quick account opening in 30 seconds',
  xuanzeyuyan: 'Choose a language',
  queren: 'confirm',
  quxiao: 'Cancel',
  
  bangzhuzhongxin: 'help center',
  chuangjianwenti: 'common problem',
  qianbaodizhi: 'wallet address',
  qingshuruqianbaodizhi:'Please enter wallet address',
  qianbaodizhibangding: 'Wallet address binding',
  xuanzetikuanfangshi: 'Choose a withdrawal method',
  qiangleituijian20: 'Highly recommend TRC20',
  chenggongzhifu: 'payment successful',
  tixiantishi: 'Cash withdrawal reminder: </br>\
		  1、Withdrawal transaction time is between 5 minutes to 1 hour to reach your account.</br>\
		  2、Withdrawal time is from 11:00 AM to 11:00 PM. If you do not receive your withdrawal for any reason, please contact customer service.</br>\
		  3、Minimum withdrawal amount is 30 THB. You can make a withdrawal once per day.</br>',
  pay: {
	  t1: 'Please use a Tron-enabled wallet to scan the QR code or copy and paste the amount and address to your wallet for payment',
	  p1: 'If you use the TP wallet to transfer, please select the currency type as USDT. It is recommended to use exchanges and imtoken transfers.',
	  p2: 'After the payment is successful, it will usually arrive in the account within a minute or so',
	  p3: 'Click the amount and address area to copy directly',
	  p4: 'The payment amount needs to be consistent with the order amount displayed above, otherwise the system may not receive the account.'
  },
  inst: {
	  t: 'Use USDT',
	  p1: 'Choose the USDT method',
	  p2: 'Fill in the balance (USDT) that needs to be recharged in the balance column, and click the "Confirm" button below',
	  p3: 'After obtaining the CALA wallet address, transfer USDT (TRC20) through the encrypted wallet app',
	  p4: 'After the transfer is completed, fill in and upload the payment wallet address and payment voucher screenshot, click "Save and send"',
	  p5: '(It is recommended to use USDT to recharge, free of handling fees, and the recharge will arrive in real time during business hours)'
  },
  
  help: {
	p1: 'How Artificial Intelligence (AI) Quantitative Trading Bots Work',
	p1_1: 'It is becoming more and more challenging to buy a product at a low price and sell it somewhere for a higher price. Due to the current congestion of the Bitcoin trading network and high transfer fees, the inefficiency of the market will lead to skyrocketing profit margins. <br>For example, there is still a price difference between Binance and Gate for BTC/USDT transactions. If you buy BTC at Gate, then send it to Binance and sell it immediately, you can get more USDT through arbitrage, then you send USDT from Binance to Gate and buy BTC. This cycle repeats itself, profiting from price differences. &nbsp;',
	p2: 'What is Smart Financial Management?',
	p2_1: 'Smart financial management represents an investment in financial management, replacement or supplementation of simple traditional financial programs or planners through machine-integrated artificial intelligence (AI). As an extension of quantitative trading investment technology, it is a global, innovative, emerging and sustainable management method with broad investment foresight, and dilutes holding costs through long-term fixed investment to achieve profit goals<br> Proven to lead to more financial planning prospects and good profits for financial managers.',
	p3: 'Advantages of intelligent robots',
	p3_1: '1. Overcome the weakness of human nature, have no greed and fear, be disciplined, strictly implement investment strategies, and not be affected by changes in investor sentiment. &nbsp;<br>2. Systematic model: multi-level quantitative model, multi-angle observation and massive data processing, combined with big data processing technology, to capture more investment opportunities. <br>3. Timely, fast and accurate: Track market changes in a timely and fast manner, constantly discover new statistical models that can provide excess returns, find new trading opportunities, and ensure accurate orders, which is incomparable to subjective transactions.',
  },
  tips: {
	  p1: 'The bot is timely, please run the bot fully to earn more income',
	  p2: 'The higher the level of the robot, the more efficient the order time and the more profit',
  },
  user: {
	  haimeizhanghu: 'no account yet',
	  yonghuming: 'username',
	  mima: 'password',
	  ninyoumeiyzhanghu: 'do you have an account',
	  qingshurnindeyonghuming: 'Please enter your username',
	  querenmima: 'Confirm Password',
	  qingshumima: 'please enter password',
	  qingshurumima: 'Please enter the confirmation password',
	  qingshuruyaoqingma: 'Please enter the invitation code',
	  yuebuzu: 'Insufficient balance',
	  huiyuandengji: 'Tier {n} Membership',
	  levjidaili: 'Tier {n} agent',
	  denglu: 'login',
	  xiugaidenglumima: 'Change login password',
	  yuanshimima: 'original password',
	  qingshuruyuanima: 'Please enter the original password',
	  xinmima: 'new password',
	  qinghuruxinmima: 'Please enter a new password',
	  liangcishurumimabuyizhi: 'The passwords entered twice are inconsistent',
	  denglumima: 'Login Password',
	  qingshurudenglumima: 'Please enter your login password'
  },
  about: {
	  p1: 'Koen Ai is a NASDAQ-listed internet brokerage house and channel for digital cryptocurrencies! With a US brokerage license and a digital currency operating license, Koen ai has received a strategic investment from the US-based PCT. Koen ai provides users with digital cryptocurrency trading and gives investors a first-class global investment experience!',
	  p2: 'Financial management, alternative or complementary investments through machine integrated artificial intelligence (AI) to simple traditional financial processes or planners.As an extension of quantitative trading investment techniques, a global, innovative, emerging and sustainable management approach with a broad investment horizon, diluting holding costs to achieve profitability goals through long-term fixed inputs proven to deliver increased financial planning prospects and lucrative profits for financial managers',
	  p3: 'Koen BOT has been licensed by the US MSB',
	  p4: 'Multi-level quantitative simulation with multiple perspectives, combined with big data processing and analysis techniques to capture more investment opportunities.',
	  p5: 'Koen Ai intelligent robot can work continuously for 24 hours. (No manual setup required, highly efficient) No need to look at the market, intelligent investing is simpler and more rational. (Suitable for most people and can be used with confidence by those without investment experience)'
  },
  proxyRule: {
	  p1: 'Agent Rebates',
	  p2: `Invite 1 person to join and you will receive 15% of your 1st level member's profit, your next level member invites a friend and you will receive 5% of the 2nd level member's next level commission and so on, up to the 3rd level member where you will receive 3% of the total 23% next level commission.`,
	  p3: 'This is a very generous benefit added by Koen Ai in order to develop the market. Every member who joins the agent earlier tends to have a rapid increase in income as the team grows, and it is a very good time to join the agent. And your agent rebate is in real time with your subordinate earnings, your agent earnings can be withdrawn at any time or continue to invest, I believe every good agent in the team has a very high income in the next few years, there is a chance for you to make your first million in the most profitable period of virtual currency.',
  },
  guanbi: 'closure',
  gonggao: 'announcement',
  genggaizijinmima: "Change Financial Password"
}
