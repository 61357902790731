<template>
	<div class="page" style="background-color: rgb(255, 255, 255); min-height: 100vh;">
		<van-nav-bar :title="$t('dailifanli')" left-arrow @click-left="onClickLeft" />

		<div class="tabs-wrapper">


			<van-tabs class="ui-tabs" @click="onTabsClick" v-model="active" animated>
				<van-tab :title=" $t('user.levjidaili', {n: '1'}) "></van-tab>
				<van-tab :title=" $t('user.levjidaili', {n: '2'}) "></van-tab>
				<van-tab :title=" $t('user.levjidaili', {n: '3'}) "></van-tab>
			</van-tabs>
			<van-pull-refresh 
				:pulling-text="$t('pullingText')"
				:loosing-text="$t('loosingText')"
				:loading-text="$t('loadingText')"
				v-model="refreshing" @refresh="onRefresh">
				<van-list :loading-text="$t('loadingText')" v-model="loading" :finished="finished" :finished-text="$t('meiyougengduo')" @load="onLoad">


					<div class="agent-list">
						<div class="ml-panel agent-card" :key="index" v-for="(item,index) in fundingRecords">
							<div class="kna-info">
								<div class="text">{{$t('jine')}}</div>
								<div class="val text-green">+{{item.price}}</div>
							</div>
							<div class="flex-btw">
								<div class="kna-info" style="max-width: 50%;">
									<div class="text">{{$t('baochou')}}</div>
									<div class="cont">{{$t('levexiajidingdan', {n:item.agent_level})}}</div>
								</div>
								<div class="kna-info">
									<div class="text">{{$t('shijian')}}</div>
									<div class="cont">{{item.create_time}}</div>
								</div>
							</div>
						</div>


					</div>

				</van-list>
			</van-pull-refresh>

		</div>
	</div>
</template>

<script>
	import {
		apiUserInfo,
		apiGetAgentFundingRecords
	} from '@/request/api'; //api接口
	export default {
		data() {
			return {
				fundingRecords: [],
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				active: 0,
				agent_level: 1,
			}
		},
		created() {
			this.getfundingRecords()
		},
		methods: {
			onTabsClick(name) {
				this.agent_level = name+1;
				this.onRefresh();
			},
			onLoad() {
				console.log(1);
				this.page++;
				this.getfundingRecords()
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;

				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				// this.refreshing = true;
				this.fundingRecords = [];
				this.page = 1;
				this.getfundingRecords();
			},
			getfundingRecords() {
				apiGetAgentFundingRecords({
					page: this.page,
					agent_level: this.agent_level
				}).then(res => {
					// this.fundingRecords = res.data.data;
					if (this.fundingRecords.length >= res.data.count) {
						this.finished = true;
					}
					this.fundingRecords = [...this.fundingRecords, ...res.data.data]
					this.loading = false;
					this.refreshing = false


				})
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			onClickRight() {}
		}
	}
</script>

<style>
</style>
